import { Card, Container, Grid, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Img } from "../shared";
import { partners } from "../constants";
import { PartnersBgIcon, PatnersBgMobileIcon } from "../assets/icons";
import { PartnersBg2, PartnersBgMobile } from "../assets/images";

const BoxStyled = styled(Box)(() => ({
  width: "24px",
  height: "14px",
  borderRadius: "6px",
  marginRight: "12px",
}));

const boxBg = ["dark", "light", "neutralGrey", "neutralWhite"];

export const Partners = () => {
  return (
    <Grid
      container
      alignItems={"center"}
      pt={{ xs: 11.25, sm: 15, md: 22 }}
      position="relative"
      id="partners"
      overflow={"hidden"}
    >
      <Container
        maxWidth="xl"
        sx={(theme) => ({
          py: { xs: 0, sm: 15, md: 24 },
          position: "relative",
          [theme.breakpoints.up("xl")]: {
            px: 7.5,
          },
        })}
      >
        <Card
          sx={{
            bgcolor: "themeLighGrey",
            borderRadius: "20px",
            pt: { xs: 3.75, sm: 3 },
            pb: { xs: 3.75, sm: 8 },
            px: { xs: 3.75, sm: 0 },
            width: "100%",
            position: "relative",
          }}
        >
          <Grid
            container
            item
            sm={10}
            m={"auto"}
            justifyContent={"space-between"}
          >
            <Grid
              item
              sm={5}
              display="flex"
              flexDirection="column"
              justifyContent={"center"}
              zIndex="1"
            >
              <Grid container item display="flex" mb={{ xs: 2, sm: 3 }}>
                {boxBg.map((bg, i) => (
                  <BoxStyled bgcolor={bg} key={`box${i}`}></BoxStyled>
                ))}
              </Grid>
              <Typography
                variant="h2"
                sx={{
                  background:
                    "linear-gradient(90deg, #7E7E7E 23.78%, #7E7E7E 81.21%, rgba(63, 63, 63, 0) 138.81%)",
                  WebkitBackgroundClip: "text",
                  textFillColor: "transparent",
                  backgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "AcehLight",
                  lineHeight: 1,
                }}
              >
                Our
              </Typography>
              <Typography
                variant="h2"
                sx={(theme) => ({
                  fontFamily: "Borned",
                  fontWeight: 400,
                  color: "themeDarkGrey",
                  textTransform: "uppercase",
                  lineHeight: 1,
                  [theme.breakpoints.down("md")]: {
                    fontSize: "32px",
                  },
                })}
              >
                Partners
              </Typography>
            </Grid>
            <Grid
              container
              item
              sm={2}
              rowSpacing={{ xs: 3, sm: 5 }}
              flexDirection={{ xs: "row", sm: "column" }}
              alignItems={"center"}
              justifyContent={"center"}
              mr={{ xs: 0, sm: 3 }}
              mt={{ xs: 15, sm: 0 }}
            >
              {partners.map((partner, i) => (
                <Grid
                  item
                  xs={6}
                  sm
                  display={"flex"}
                  justifyContent={"center"}
                  px={{ xs: 2, sm: 0 }}
                  key={`container${i}`}
                >
                  <Img
                    src={partner.logo}
                    alt={partner.alt}
                    sx={{ transform: "translateZ(0px)" }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Img
            src={PartnersBgMobile}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              display: { xs: "block", sm: "none" },
            }}
          />
        </Card>
        <PartnersBgIcon
          sx={(theme) => ({
            display: { xs: "none", lg: "block" },
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: -1,
            [theme.breakpoints.between("lg", "xlg")]: {
              right: "-225px !important",
            },
          })}
        />
        <Img
          src={PartnersBg2}
          sx={(theme) => ({
            position: "absolute",
            right: "80px",
            display: { xs: "none", lg: "block" },
            top: "194px",
            [theme.breakpoints.between("lg", "xlg")]: {
              right: "-146px !important",
            },
          })}
        />
      </Container>

      <PatnersBgMobileIcon
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          right: "1.3px",
          top: "25px",
          zIndex: 2,
        }}
      />
    </Grid>
  );
};
