import { Grid } from "@mui/material";
import {
  AboutUs,
  Web3Solutions,
  Footer,
  Header,
  NavBar,
  Partners,
} from "./components";
import "./App.css";

function OmniDAOLanding() {
  return (
    <Grid sx={{ position: "relative" }}>
      <NavBar />
      <Header />
      <Web3Solutions />
      <AboutUs />
      <Partners />
      <Footer />
    </Grid>
  );
}

export default OmniDAOLanding;
