import { createTheme } from "@mui/material";

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    main: "#000000",
    dark: "#3F3F3F",
    light: "#7E7E7E",
    themeDarkGrey: "#242427",
    themeLighGrey: "#EEF0F2",
    neutralGrey: "#BDBDBD",
    neutralWhite: "#FFFFFF",
    lowFidelity: "#39393E",
    lowFidelitySecondary: "#5B5A65",
    lowFidelityText: "#CECFD5",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xlg: 1400,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "#000000",
          fontFamily: "Aceh",
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          maxWidth: "1440px",
          [defaultTheme.breakpoints.down("xl")]: {
            padding: "0px 80px",
          },
          [defaultTheme.breakpoints.down("lg")]: {
            padding: "0px 25px",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#EEF0F2",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "uppercase",
          textDecoration: "none",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Aceh",
          fontWeight: "unset",
        },
        h1: {
          fontSize: "72px",
          color: "#FFFFFF",
        },
        h2: {
          fontSize: "64px",
          [defaultTheme.breakpoints.down("sm")]: {
            fontSize: "32px",
          },
        },
        h4: {
          fontFamily: "AcehMedium",
          fontSize: "32px",
          color: "#FFFFFF",
          lineHeight: "32px",
          [defaultTheme.breakpoints.down("md")]: {
            fontSize: "24px",
          },
        },
        h3: {
          fontSize: "48px",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: "auto",
          height: "auto",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "unset",
        },
      },
    },
  },
});

export default theme;
