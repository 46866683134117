import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-scroll";

export const NavigationsLinks = ({ links, styles, ...props }) => {
  return links.map((navItem, i) => (
    <Grid item sm textAlign={"end"} key={`nav-link${i}`}>
      <Link
        style={{
          styles,
          color: "#fff",
          cursor: "pointer",
          textTransform: "uppercase",
          textDecoration: "none",
          fontFamily: "Aceh",
          whiteSpace: "nowrap",
        }}
        to={navItem.id}
        className={navItem.id}
        activeClass="active"
        spy={true}
        offset={-91}
        {...props}
        href={navItem.id}
      >
        {navItem.name}
      </Link>
    </Grid>
  ));
};
