import React from "react";
import { SvgIcon } from "@mui/material";

export const MenuIcon = ({ ...props }) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1H23.5" stroke="white" strokeWidth="2" />
      <path d="M0 9H23.5" stroke="white" strokeWidth="2" />
      <path d="M0 17H23.5" stroke="white" strokeWidth="2" />
    </SvgIcon>
  );
};
