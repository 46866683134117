import { SvgIcon } from "@mui/material";
export const DownArrowIcon = ({ ...props }) => {
  return (
    <SvgIcon
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="41"
        height="41"
        fill="#242427"
        stroke="#EEF0F2"
      />
      <g clipPath="url(#clip0_1868_238)">
        <path
          d="M21.0002 13V29M21.0002 29L16.0002 23.6667M21.0002 29L26.0002 23.6667"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1868_238">
          <rect
            width="17.3793"
            height="17.3793"
            fill="white"
            transform="translate(12.3108 12.3105)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
