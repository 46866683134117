import { useState } from "react";
import { Grid, Container, useMediaQuery } from "@mui/material";
import { Img, Drawer, LaunchButton, NavigationsLinks } from "../shared";
import { MenuIcon, CloseIcon } from "../assets/icons";
import { Logo, NavbarBottomBg, NavbarTopBg } from "../assets/images";
import { links } from "../constants";

export const NavBar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <Grid
      container
      py={3}
      bgcolor={"main"}
      position={"sticky"}
      top={"0"}
      zIndex={111}
    >
      <Container maxWidth="xl">
        <Grid container flexWrap="nowrap" justifyContent="space-between">
          <Grid item sm={2} md={2} lg={5}>
            <Img src={Logo} sx={{ cursor: "pointer" }} alt="OMNI DAO" />
          </Grid>
          <Grid
            container
            item
            sm={9}
            md={10}
            lg={7}
            columnSpacing={5}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexWrap={"nowrap"}
          >
            {matches ? (
              <Grid item sx={{ position: "relative" }}>
                <Drawer
                  open={openDrawer}
                  closeDrawer={() => setOpenDrawer(false)}
                  sx={{
                    "& .MuiPaper-root": {
                      width: "100%",
                      bgcolor: "main",
                    },
                  }}
                >
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    py={"18px"}
                    px={"25px"}
                  >
                    <Img src={Logo} sx={{ cursor: "pointer" }} />
                    {openDrawer && (
                      <CloseIcon
                        onClick={() => setOpenDrawer(false)}
                        width="14px"
                        zIndex="11"
                      />
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    direction="column"
                    xs={12}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      zIndex: "11",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 2,
                        p: 3,
                      }}
                    >
                      <Grid
                        container
                        item
                        direction={"column"}
                        alignItems={"center"}
                        spacing={5}
                      >
                        <NavigationsLinks links={links} />
                      </Grid>
                      <Grid item>
                        <LaunchButton
                          sx={{ fontSize: "16px", px: 2, mt: 8 }}
                          disabled
                        >
                          NFT Suite coming soon
                        </LaunchButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Img
                    src={NavbarBottomBg}
                    sx={{ position: "absolute", bottom: 0, right: 0 }}
                  />
                  <Img
                    src={NavbarTopBg}
                    sx={{ position: "absolute", top: 0, left: 0 }}
                  />
                </Drawer>
                <Grid item>
                  {!openDrawer && (
                    <MenuIcon onClick={() => setOpenDrawer(true)} />
                  )}
                </Grid>
              </Grid>
            ) : (
              <>
                <NavigationsLinks links={links} />
                <Grid item>
                  <LaunchButton sx={{ fontSize: "16px", px: 2 }} disabled>
                    NFT Suite coming soon
                  </LaunchButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
