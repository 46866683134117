import { Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.neutralWhite,
  color: theme.palette.main,
  padding: "8px 32px",
  borderRadius: "0px",
  fontWeight: 600,
  fontSize: "24px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: theme.palette.neutralWhite,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

export const PrimaryButton = ({ children, ...props }) => {
  return (
    <StyledButton disableRipple {...props}>
      {children}
    </StyledButton>
  );
};
