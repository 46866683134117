import { Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.themeDarkGrey,
  color: theme.palette.themeLighGrey,
  padding: "8px 12px",
  borderRadius: "0px",
  fontWeight: 400,
  height: "42px",
  fontSize: "14px",
  whiteSpace: "nowrap",
  fontFamily: "Aceh",
  border: "1.44828px solid theme.palette.lowFidelitySecondary",
  "&:hover": {
    backgroundColor: theme.palette.themeDarkGrey,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
  "&.Mui-disabled": {
    color: theme.palette.neutralGrey,
    backgroundColor: theme.palette.light,
  },
}));

export const LaunchButton = ({ children, ...props }) => {
  return (
    <StyledButton disableRipple {...props}>
      {children}
    </StyledButton>
  );
};
