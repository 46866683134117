import { Grid, Box, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Img } from "../shared";
import { TextLogo, AboutUsLogo, Product, AboutLine } from "../assets/images";
const BoxStyled = styled(Box)(() => ({
  width: "24px",
  height: "14px",
  borderRadius: "6px",
  marginRight: "12px",
}));
const boxBg = ["dark", "light", "neutralGrey", "neutralWhite"];

export const AboutUs = () => {
  return (
    <Grid container position={"relative"} id="about-us" pt={22.125}>
      <Container maxWidth="xl">
        <Grid
          item
          // pt={20}
          pb={{ xs: 11, sm: 14, md: 18 }}
          container
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: { flexWrap: "wrap-reverse" },
          })}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={(theme) => ({
              position: "relative",
              ml: 7,
              "@media (max-width:768px)": {
                mt: 6,
              },

              "@media (max-width:1050px)": {
                ml: 0,
              },
              "@media (max-width:600px)": {
                ml: 2,
              },
            })}
          >
            <Img
              src={TextLogo}
              sx={(theme) => ({
                width: "353px",
                [theme.breakpoints.down("lg")]: {
                  width: "300px",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "200px",
                },
              })}
            />
            <Box
              sx={(theme) => ({
                width: "248px",
                height: "248px",
                borderRadius: "50%",
                background:
                  "linear-gradient(145.33deg, rgba(75, 79, 96, 0.1) 22.89%, rgba(138, 143, 167, 0.38) 86.62%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "249px",
                left: "255px",
                zIndex: "-1",
                [theme.breakpoints.down("lg")]: {
                  left: "160px",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "140px",
                  height: "140px",
                  top: "144px",
                  left: "144px",
                  padding: "12px",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  width: "202px",
                  height: "202px",
                  borderRadius: "50%",
                  bgcolor: "main",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  [theme.breakpoints.down("sm")]: {
                    width: "114px",
                    height: "114px",
                  },
                })}
              >
                <Img
                  src={AboutUsLogo}
                  sx={{ width: { xs: "80px", sm: "auto" } }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid
              container
              item
              display="flex"
              mb={{ xs: 2, sm: 3 }}
              pr={{ xs: 0, sm: 3.5 }}
            >
              {boxBg.map((bg, i) => (
                <BoxStyled bgcolor={bg} key={`box${i}`}></BoxStyled>
              ))}
            </Grid>
            <Typography
              variant="h1"
              sx={(theme) => ({
                mb: 7,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "40px",
                  lineHeight: "36px",
                  mb: 4,
                },
              })}
            >
              About us
            </Typography>
            <Typography
              variant="body1"
              sx={(theme) => ({
                fontSize: "24px",
                color: "lowFidelityText",
                lineHeight: "28.8px",
                mb: 2,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "18px",
                  lineHeight: "25px",
                },
              })}
            >
              OMNI DAO was formed around the idea of&nbsp;
              <Typography
                variant="caption"
                sx={(theme) => ({
                  fontSize: "24px",
                  fontFamily: "AcehBold",
                  color: "neutralWhite",
                  lineHeight: "28.8px",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "18px",
                    lineHeight: "25px",
                  },
                })}
              >
                building WEB3 Solutions and putting power back in the hands of
                our users.
              </Typography>
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: "24px",
                fontWeight: 700,
                color: "neutralWhite",
                lineHeight: "28.8px",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "18px",
                  lineHeight: "25px",
                },
              })}
            >
              We aim to support and develop solutions across most major Layer 1
              Blockchains,&nbsp;
              <Typography
                variant="caption"
                sx={(theme) => ({
                  fontSize: "24px",
                  fontWeight: 400,
                  color: "lowFidelityText",
                  lineHeight: "28.8px",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "18px",
                    lineHeight: "25px",
                  },
                })}
              >
                while also generating revenue from our products to inject back
                into the ecosystems via; Grants Programs, Activations,
                Networking Opportunities, and more.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Img
        src={Product}
        sx={{
          position: "absolute",
          bottom: "-415px",
          display: { xs: "none", sm: "block" },
          zIndex: -3,
        }}
      />
      <Img
        src={AboutLine}
        sx={{
          position: "absolute",
          right: 0,
          bottom: "-24px",
          display: { xs: "none", md: "block" },
        }}
      />
    </Grid>
  );
};
