import { Card, Grid, Typography } from "@mui/material";
import { SettingIcon } from "../assets/icons";
import { PrimaryButton } from "./PrimaryButton";

export const SolutionCard = () => {
  return (
    <Card
      sx={(theme) => ({
        background:
          "linear-gradient(97.68deg, rgba(108, 109, 112, 0.042) -8.24%, rgba(43, 45, 51, 0.142) 87.55%, rgba(43, 45, 51, 0.116) 87.55%)",
        border: "1px solid",
        [theme.breakpoints.down("sm")]: {
          border: "unset",
        },
        borderColor: "neutralWhite",
        px: { xs: 3.5, sm: 3, md: 6 },
        py: { xs: 5, sm: 6 },
        backdropFilter: "blur(9px)",
        display: "flex",
        alignItems: "center",
        height: "100%",
      })}
    >
      <Grid container flexDirection="column" alignItems="flex-start">
        <Grid item display={"flex"} xs={9} sm={10} lg={6}>
          <SettingIcon
            sx={{
              width: { xs: "32px", sm: "auto" },
              fill: { xs: "#39393E", sm: "#fff" },
            }}
          />
          <Typography
            variant="h4"
            sx={{
              ml: 2.5,
            }}
          >
            The NFT Suite
          </Typography>
        </Grid>
        <Typography
          sx={(theme) => ({
            fontSize: "24px",
            color: "neutralGrey",
            opacity: 0.6,
            mt: 2,
            mb: 4,
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
            },
          })}
        >
          Creator-Side NFT Tooling Made Easy
        </Typography>
        <PrimaryButton
          sx={(theme) => ({
            border: "2px solid",
            borderColor: "lowFidelity",
            color: "lowFidelity",
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              height: "37px",
            },
          })}
          disabled
        >
          Coming Soon
        </PrimaryButton>
      </Grid>
    </Card>
  );
};
