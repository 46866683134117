import { Card, Grid, Link, Typography } from "@mui/material";
import { SettingIcon } from "../assets/icons";
import { PrimaryButton } from "./PrimaryButton";
export const ContactCard = () => {
  return (
    <Card
      sx={{
        background:
          "linear-gradient(97.68deg, rgba(108, 109, 112, 0.021) -8.24%, rgba(43, 45, 51, 0.071) 87.55%, rgba(43, 45, 51, 0.058) 87.55%)",
        borderColor: "neutralWhite",
        px: { xs: 3.5, sm: 3, md: 6 },
        py: { xs: 5, md: 6 },
        backdropFilter: "blur(9px)",
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Grid
        container
        item
        flexDirection="column"
        alignItems={"flex-start"}
        xs={12}
        lg={11}
      >
        <Grid item display={"flex"} xs={6}>
          <SettingIcon
            sx={{ width: { xs: "32px", sm: "auto" }, fill: "#39393E" }}
          />
          <Typography
            variant="h4"
            sx={{
              color: "lowFidelityText",
              ml: 2.5,
            }}
          >
            Project Incubation
          </Typography>
        </Grid>
        <Typography
          sx={(theme) => ({
            fontSize: "24px",
            color: "neutralGrey",
            opacity: 0.6,
            mt: 2,
            mb: 4,
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
            },
          })}
        >
          Helping projects build a strong foundation by connecting them with
          important individuals, businesses, and foundations.
        </Typography>
        <Link href="mailto:contact@wgmisolutions.io">
          <PrimaryButton
            sx={(theme) => ({
              border: "2px solid",
              borderColor: "lowFidelitySecondary",
              color: "lowFidelitySecondary",
              background: "none",
              [theme.breakpoints.down("sm")]: {
                borderColor: "neutralWhite",
                color: "neutralWhite",
                height: "37px",
              },
            })}
          >
            Contact Us
          </PrimaryButton>
        </Link>
      </Grid>
    </Card>
  );
};
