import { SvgIcon } from "@mui/material";
export const HeaderUpperLinesIcon = ({ ...props }) => {
  return (
    <SvgIcon
      width="491"
      height="674"
      viewBox="0 0 491 674"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M291.605 94.762C310.697 128.048 299.445 173.214 285.223 230.3C267.684 300.729 245.851 388.39 268.481 495.976C270.985 507.897 269.197 515.409 263.149 518.294C241.692 528.54 171.788 470.262 122.466 411.545C-67.1285 185.855 -260.021 12.9394 -387.668 -101.479C-485.847 -189.494 -545.108 -242.614 -549.931 -269.385C-550.895 -274.747 -548.88 -277.525 -547.015 -278.9C-532.244 -289.853 -467.165 -257.868 -361.745 -203.893C-324.792 -184.975 -286.587 -165.411 -253.834 -150.199C-215.246 -132.273 -149.138 -107.219 -79.1539 -80.6881C82.2209 -19.5195 265.135 49.8117 291.212 94.0776C291.342 94.3034 291.475 94.5362 291.605 94.762V94.762ZM-541.225 -258.735C-524.674 -229.88 -466.919 -178.103 -384.921 -104.598C-257.19 9.90411 -64.1525 182.944 125.64 408.867C178.152 471.385 244.078 522.71 261.294 514.484C265.408 512.517 266.446 506.563 264.392 496.77C241.56 388.224 263.517 300.043 281.166 229.197C295.156 173.037 306.218 128.596 287.991 96.8011C287.869 96.5894 287.748 96.3777 287.627 96.1661C262.326 53.2077 72.2125 -18.8482 -80.552 -76.7545C-153.654 -104.463 -216.795 -128.394 -255.52 -146.383C-288.351 -161.635 -326.593 -181.215 -363.583 -200.149C-441.913 -240.255 -530.702 -285.707 -544.482 -275.493C-545.353 -274.85 -546.435 -273.582 -545.805 -270.05C-545.217 -266.784 -543.67 -263.015 -541.218 -258.739L-541.225 -258.735Z"
        fill="url(#paint0_linear_1732_168)"
      />
      <path
        d="M278.231 96.0426C296.756 128.341 285.725 171.812 271.783 226.776C254.847 293.535 233.767 376.619 254.563 478.205C256.892 489.516 255.125 496.65 249.317 499.409C228.722 509.204 161.65 453.415 114.34 397.231C-67.0068 181.776 -251.755 16.274 -374.012 -93.245C-465.128 -174.867 -525.324 -228.786 -530.438 -254.766C-531.685 -260.285 -529.745 -263.18 -527.899 -264.619C-513.759 -275.663 -449.917 -244.715 -346.466 -192.428C-312.283 -175.156 -276.942 -157.287 -246.551 -143.232C-210.652 -126.633 -152.821 -104.404 -85.8534 -78.6682C64.7636 -20.7778 252.213 51.2564 277.928 95.5315C278.03 95.7079 278.131 95.8843 278.232 96.0606L278.231 96.0426ZM-521.959 -244.913C-505.979 -217.052 -450.36 -167.224 -371.269 -96.3704C-248.921 13.2278 -64.0349 178.857 117.51 394.546C167.842 454.318 230.999 503.426 247.462 495.599C251.337 493.758 252.344 488.167 250.462 478.978C229.468 376.438 250.683 292.838 267.722 225.666C281.425 171.631 292.27 128.893 274.607 98.0967C274.514 97.9344 274.421 97.7721 274.328 97.6099L274.324 97.6028C249.387 54.6533 62.7173 -17.0938 -87.2844 -74.7439C-154.321 -100.506 -212.212 -122.758 -248.236 -139.416C-278.69 -153.501 -314.076 -171.382 -348.289 -188.674C-425.115 -227.501 -512.187 -271.514 -525.291 -261.273C-525.787 -260.889 -527.281 -259.715 -526.341 -255.564C-525.732 -252.469 -524.253 -248.913 -521.959 -244.913V-244.913Z"
        fill="url(#paint1_linear_1732_168)"
      />
      <path
        d="M264.842 97.3135C282.809 128.639 271.995 170.407 258.322 223.246C241.995 286.332 221.669 364.838 240.633 460.414C242.775 471.111 241.035 477.874 235.473 480.504C215.742 489.836 151.51 436.55 106.205 382.885C-66.8902 177.672 -243.498 19.577 -360.365 -85.0422C-447.777 -163.296 -505.528 -214.983 -510.954 -240.178C-512.188 -244.732 -511.483 -248.208 -508.808 -250.397C-494.277 -262.345 -418.599 -224.625 -330.977 -180.95C-299.641 -165.327 -267.24 -149.177 -239.274 -136.308C-206.126 -121.048 -153.607 -100.575 -92.7925 -76.8578C55.1177 -19.1899 239.182 52.5762 264.619 96.9255C264.688 97.0454 264.769 97.1865 264.842 97.3135V97.3135ZM-502.716 -231.114C-487.31 -204.255 -433.817 -156.372 -357.618 -88.1606C-240.659 16.5377 -63.9182 174.753 109.375 380.2C157.519 437.23 217.904 484.114 233.632 476.686C237.274 474.967 238.244 469.743 236.531 461.169C217.375 364.635 237.837 285.613 254.273 222.11C267.705 170.211 278.329 129.168 261.232 99.3597C261.167 99.2469 261.103 99.134 261.038 99.0211L261.009 98.9717C236.348 55.959 53.0537 -15.505 -94.2304 -72.9295C-155.111 -96.6653 -207.681 -117.165 -240.951 -132.478C-268.98 -145.376 -301.411 -161.547 -332.781 -177.18C-372.662 -197.056 -413.898 -217.613 -445.888 -231.333C-489.031 -249.837 -502.456 -250.127 -506.126 -247.113C-506.613 -246.714 -508.083 -245.498 -506.89 -241.098C-506.264 -238.181 -504.853 -234.857 -502.713 -231.125L-502.716 -231.114Z"
        fill="url(#paint2_linear_1732_168)"
      />
      <path
        d="M251.229 98.1784C251.306 98.3124 251.387 98.4535 251.464 98.5876C268.877 128.946 258.272 169.016 244.85 219.732C229.13 279.136 209.567 353.068 226.704 442.641C228.659 452.723 226.952 459.113 221.637 461.612C202.759 470.48 141.367 419.696 98.0747 368.565C-66.7687 173.593 -235.236 22.9049 -346.709 -76.8074C-430.333 -151.609 -485.601 -201.048 -491.441 -225.477C-492.948 -230.297 -492.373 -233.905 -489.733 -236.186C-475.78 -248.246 -401.385 -211.705 -315.239 -169.39C-286.833 -155.434 -257.459 -141.008 -231.999 -129.355C-201.686 -115.47 -154.573 -96.8319 -100.025 -75.2372C45.1144 -17.7925 225.688 53.6634 251.225 98.1713L251.229 98.1784ZM247.734 100.44L247.73 100.433C247.689 100.363 247.649 100.292 247.608 100.221C222.85 57.0391 35.4322 -17.1285 -101.48 -71.3081C-156.083 -92.9185 -203.249 -111.583 -233.661 -125.515C-259.173 -137.194 -288.573 -151.634 -317.013 -165.599C-387.432 -200.186 -475.072 -243.234 -486.971 -232.956C-487.451 -232.543 -488.91 -231.277 -487.433 -226.589L-487.391 -226.435C-481.962 -203.363 -427.067 -154.255 -343.962 -79.9258C-232.398 19.8657 -63.7968 170.674 101.245 365.88C147.208 420.163 204.83 464.837 219.807 457.797C223.205 456.198 224.149 451.344 222.603 443.366C205.286 352.839 224.984 278.391 240.807 218.573C253.981 168.802 264.381 129.466 247.847 100.638C247.81 100.574 247.778 100.518 247.742 100.454L247.734 100.44V100.44Z"
        fill="url(#paint3_linear_1732_168)"
      />
      <path
        d="M237.828 99.4284C237.909 99.5695 237.986 99.7035 238.067 99.8447L238.075 99.8588C254.937 129.258 244.533 167.644 231.357 216.229C216.25 271.948 197.448 341.299 212.775 424.867C214.547 434.343 212.87 440.352 207.794 442.724C189.773 451.134 131.224 402.843 89.9445 354.244C-66.6403 169.51 -226.959 26.2435 -333.038 -68.5619C-412.946 -139.973 -465.773 -187.188 -471.942 -210.832C-473.736 -215.864 -473.315 -219.627 -470.692 -222.002C-458.204 -233.334 -394.678 -203.917 -299.19 -157.753C-273.816 -145.481 -247.571 -132.796 -224.716 -122.388C-197.339 -109.923 -155.754 -93.2092 -107.602 -73.8505C27.4027 -19.5889 212.134 54.6631 237.836 99.4425L237.828 99.4284ZM234.442 101.881L234.425 101.852C234.348 101.718 234.272 101.584 234.195 101.45C209.293 58.0027 25.3374 -15.922 -109.092 -69.9488C-157.299 -89.3232 -198.921 -106.054 -226.386 -118.561C-249.29 -128.989 -275.55 -141.685 -300.95 -153.969C-339.221 -172.478 -378.797 -191.612 -409.818 -204.46C-451.762 -221.831 -464.536 -221.883 -467.86 -218.858C-469.139 -217.694 -469.179 -215.427 -467.981 -212.105L-467.921 -211.905C-462.199 -189.57 -409.731 -142.675 -330.306 -71.6906C-224.136 23.194 -63.6834 166.582 93.0956 351.542C136.878 403.079 191.732 445.536 205.963 438.891C209.128 437.415 210.034 432.928 208.661 425.554C193.16 341.027 212.102 271.167 227.323 215.038C240.237 167.405 250.441 129.759 234.465 101.905L234.448 101.877L234.442 101.881Z"
        fill="url(#paint4_linear_1732_168)"
      />
      <path
        d="M224.418 100.647C224.543 100.865 224.661 101.07 224.782 101.282C240.972 129.717 230.763 166.346 217.85 212.716C203.363 264.747 185.323 329.505 198.849 407.083C200.438 415.953 198.787 421.591 193.954 423.826C183.695 428.575 162.444 414.817 146.429 402.438C125.367 386.16 101.809 363.369 81.8138 339.906C-66.5152 165.421 -218.682 29.5642 -319.368 -60.3343C-395.564 -128.363 -445.959 -173.338 -452.438 -196.208C-454.548 -201.472 -454.295 -205.402 -451.676 -207.896C-439.631 -219.36 -376.98 -190.859 -282.767 -146.056C-259.526 -135.006 -237.581 -124.568 -217.434 -115.456C-193.151 -104.464 -157.254 -89.8071 -115.684 -72.8316C17.0075 -18.6508 198.51 55.4601 224.432 100.639L224.418 100.647ZM-445.139 -189.902C-431.364 -165.886 -382.157 -121.957 -316.632 -63.4559C-215.855 26.5217 -63.5542 162.499 84.9649 337.203C126.564 386.006 178.646 426.239 192.141 419.992C195.067 418.642 195.939 414.511 194.726 407.738C181.037 329.204 199.21 263.941 213.821 211.503C226.475 166.074 236.466 130.185 221.145 103.296L221.125 103.261C221.007 103.057 220.897 102.848 220.776 102.636C195.633 58.8013 14.9242 -14.9831 -117.203 -68.9323C-158.806 -85.9171 -194.74 -100.59 -219.097 -111.615C-239.28 -120.743 -261.247 -131.188 -284.504 -142.248C-322.051 -160.102 -360.872 -178.56 -391.531 -191.016C-433.08 -207.891 -445.595 -207.827 -448.779 -204.798C-450.062 -203.576 -449.981 -201.163 -448.535 -197.616L-448.455 -197.381C-447.841 -195.158 -446.721 -192.661 -445.139 -189.902Z"
        fill="url(#paint5_linear_1732_168)"
      />
      <path
        d="M210.998 101.833C211.172 102.136 211.338 102.425 211.49 102.723C226.998 130.208 216.991 165.077 204.319 209.226C190.448 257.561 173.185 317.719 184.913 389.314C186.315 397.57 184.698 402.834 180.111 404.938C170.327 409.427 150.151 396.406 134.946 384.69C114.999 369.314 92.6634 347.774 73.6728 325.582C-66.3893 161.35 -210.401 32.9101 -305.695 -52.0704C-378.199 -116.733 -426.16 -159.499 -432.95 -181.566C-435.407 -187.082 -435.328 -191.203 -432.709 -193.809C-421.052 -205.438 -359.089 -177.769 -265.891 -134.224C-246.12 -124.983 -227.442 -116.255 -210.162 -108.491C-189.175 -99.0521 -159.185 -86.6102 -124.46 -72.2052C6.165 -18.0113 184.745 56.0933 211.002 101.84L210.998 101.833ZM-425.931 -176.101C-412.755 -153.129 -365.719 -111.176 -302.952 -55.1959C-207.569 29.8747 -63.4283 158.428 76.824 322.88C116.235 368.94 165.556 406.952 178.309 401.108C180.998 399.873 181.83 396.12 180.78 389.937C168.894 317.393 186.3 256.733 200.285 207.988C212.68 164.781 222.476 130.648 207.838 104.727L207.814 104.685C207.667 104.413 207.512 104.127 207.354 103.852C181.868 59.4165 -3.24731 -17.3883 -125.998 -68.3231C-160.749 -82.7413 -190.765 -95.1965 -211.81 -104.659C-229.116 -112.435 -247.806 -121.167 -267.598 -130.413C-304.477 -147.646 -342.607 -165.459 -373.021 -177.567C-414.357 -194.021 -426.678 -193.844 -429.731 -190.795C-431.337 -189.192 -430.263 -185.735 -429.085 -183.121L-428.989 -182.857C-428.377 -180.829 -427.352 -178.578 -425.935 -176.108L-425.931 -176.101Z"
        fill="url(#paint6_linear_1732_168)"
      />
      <path
        d="M197.58 103.037C197.803 103.425 198.013 103.792 198.207 104.178C213.043 130.716 203.206 163.834 190.764 205.759C177.516 250.394 161.026 305.945 170.985 371.559C172.205 379.208 170.616 384.091 166.276 386.065C156.956 390.291 137.861 378.003 123.472 366.955C104.638 352.481 83.5217 332.186 65.536 311.266C-66.2593 157.286 -202.111 36.2703 -292.012 -43.8032C-360.822 -105.1 -406.337 -145.634 -413.45 -166.92C-416.295 -172.713 -416.405 -177.035 -413.781 -179.775C-402.439 -191.617 -340.943 -164.684 -248.422 -122.24C-232.322 -114.855 -217.112 -107.882 -202.897 -101.541C-185.505 -93.7752 -161.733 -83.7546 -134.211 -72.1575C-12.5352 -20.874 170.825 56.4223 197.569 103.034L197.58 103.037ZM-406.743 -162.317C-394.161 -140.382 -349.289 -100.409 -289.266 -46.9398C-199.281 33.2168 -63.2993 154.346 68.686 308.545C105.916 351.859 152.473 387.662 164.49 382.215C166.947 381.103 167.737 377.711 166.849 372.128C156.749 305.564 173.376 249.515 186.741 204.477C198.907 163.494 208.511 131.125 194.542 106.161L194.51 106.105C194.329 105.758 194.13 105.395 193.928 105.042C167.961 59.7378 -21.9401 -20.3019 -135.76 -68.2784C-163.308 -79.8888 -187.095 -89.9196 -204.531 -97.6978C-218.771 -104.052 -233.986 -111.032 -250.096 -118.421C-286.381 -135.065 -323.902 -152.279 -354.225 -164.084C-395.585 -180.197 -407.795 -179.905 -410.746 -176.821C-412.371 -175.124 -411.05 -171.413 -409.648 -168.599L-409.529 -168.311C-408.933 -166.489 -407.996 -164.487 -406.75 -162.313L-406.743 -162.317Z"
        fill="url(#paint7_linear_1732_168)"
      />
      <path
        d="M184.149 104.202C184.424 104.682 184.68 105.145 184.92 105.626C199.078 131.24 189.409 162.599 177.174 202.294C164.556 243.225 148.859 294.157 157.061 353.794C158.098 360.835 156.543 365.344 152.445 367.181C136.283 374.415 86.8771 331.037 57.4207 296.938C-66.1035 153.217 -188.736 44.1253 -278.287 -35.5304C-343.415 -93.4644 -386.496 -131.77 -393.929 -152.286C-397.2 -158.389 -397.515 -162.941 -394.878 -165.819C-383.796 -177.907 -319.618 -150.333 -230.16 -110.061C-217.994 -104.587 -206.505 -99.4122 -195.602 -94.5877C-182.296 -88.6946 -165.178 -81.3726 -145.366 -72.8936C-32.1105 -24.4465 156.669 56.3244 184.153 104.21L184.149 104.202ZM-387.572 -148.55C-375.577 -127.638 -332.864 -89.6373 -275.555 -38.6591C-185.919 41.0796 -63.1534 150.292 60.5569 294.225C95.6062 334.793 139.406 368.382 150.681 363.337C152.901 362.341 153.661 359.311 152.918 354.319C144.583 293.729 160.434 242.298 173.171 200.982C185.118 162.227 194.556 131.615 181.258 107.599L181.222 107.535C157.586 61.2367 -40.5356 -23.495 -146.944 -69.0169C-166.773 -77.4951 -183.896 -84.8241 -197.228 -90.7305C-208.142 -95.5582 -219.642 -100.736 -231.811 -106.217C-265.923 -121.568 -304.58 -138.971 -335.001 -150.562C-376.725 -166.46 -388.91 -166.06 -391.782 -162.918C-393.436 -161.111 -391.831 -157.112 -390.188 -154.089L-390.05 -153.784C-389.48 -152.181 -388.648 -150.427 -387.568 -148.543L-387.572 -148.55Z"
        fill="url(#paint8_linear_1732_168)"
      />
      <path
        d="M170.692 105.308C171.032 105.901 171.346 106.48 171.635 107.064C185.129 131.774 175.597 161.373 163.539 198.846C151.556 236.051 136.655 282.352 143.134 336.039C144.19 344.06 141.254 347.137 138.606 348.301C124.659 354.448 81.156 319.298 49.2805 282.615C-65.9576 149.164 -180.75 47.2103 -264.568 -27.2351C-326.037 -81.8307 -366.693 -117.941 -374.433 -137.647L-374.554 -137.859C-378.237 -144.279 -378.732 -148.888 -376.059 -151.928C-365.112 -164.396 -300.667 -137.254 -210.817 -97.5486C-203.068 -94.1245 -195.53 -90.7959 -188.341 -87.6435C-179.995 -83.9784 -170.078 -79.6886 -158.961 -74.8736C-53.3687 -29.1592 142.087 55.4679 170.675 105.309L170.692 105.308ZM-368.425 -134.825C-357.013 -114.928 -316.441 -78.8834 -261.826 -30.3787C-177.917 44.1459 -63.0086 146.221 52.4185 279.873C85.2799 317.699 126.323 349.073 136.862 344.427C138.849 343.553 139.564 340.877 138.978 336.478C132.381 281.849 147.445 235.062 159.549 197.461C171.31 160.942 180.592 132.092 167.956 108.99L167.916 108.92C167.651 108.378 167.353 107.827 167.038 107.277C139.203 58.7481 -62.8589 -28.7355 -160.537 -71.0259C-171.665 -75.844 -181.593 -80.1369 -189.939 -83.802C-197.139 -86.9576 -204.677 -90.2861 -212.43 -93.7172C-246.142 -108.613 -284.357 -125.5 -315.17 -136.99C-359.997 -153.701 -370.575 -151.764 -372.9 -149.127C-374.687 -147.086 -372.333 -142.278 -370.756 -139.609L-370.667 -139.454L-370.603 -139.294C-370.069 -137.914 -369.344 -136.427 -368.433 -134.839L-368.425 -134.825Z"
        fill="url(#paint9_linear_1732_168)"
      />
      <path
        d="M157.252 106.442C157.653 107.14 158.02 107.829 158.359 108.516C171.193 132.348 161.303 161.579 149.85 195.418C137.93 230.634 124.427 270.553 129.211 318.291C130.042 325.552 127.257 328.365 124.783 329.43C116.865 332.852 100.99 322.768 89.0631 313.709C73.5709 301.94 56.1142 285.385 41.1542 268.284C-65.7895 145.116 -172.724 50.3009 -250.809 -18.9342C-308.623 -70.1983 -346.879 -104.126 -354.919 -123.026C-359.239 -129.965 -360.024 -134.887 -357.284 -138.132C-346.338 -151.114 -278.005 -122.828 -189.815 -84.499L-177.111 -78.9842C-93.5671 -42.6869 126.554 52.9533 157.248 106.435L157.252 106.442ZM-349.304 -121.113C-338.463 -102.211 -300.013 -68.1225 -248.065 -22.0598C-169.9 47.2513 -62.8405 142.173 44.2893 265.553C74.9699 300.633 113.256 329.793 123.068 325.56C124.818 324.801 125.491 322.484 125.054 318.665C120.174 269.99 133.838 229.616 145.887 193.999C157.491 159.72 166.651 132.641 154.669 110.439L154.625 110.362C154.309 109.699 153.949 109.024 153.561 108.347C123.697 56.265 -95.5038 -38.9714 -178.693 -75.1144L-191.39 -80.6332C-224.794 -95.152 -262.659 -111.603 -294.318 -123.175C-340.889 -140.188 -351.731 -138.119 -354.053 -135.363C-355.424 -133.735 -354.433 -130.071 -351.291 -125.073L-351.202 -124.918L-351.127 -124.755C-350.648 -123.615 -350.041 -122.397 -349.304 -121.113V-121.113Z"
        fill="url(#paint10_linear_1732_168)"
      />
      <path
        d="M143.75 107.452C144.24 108.306 144.682 109.14 145.072 109.965C157.266 132.954 147.451 160.33 136.085 192.016C124.868 223.29 112.155 258.741 115.292 300.551C115.895 307.062 113.271 309.596 110.957 310.572C98.3237 315.89 60.6758 285.329 33.0362 253.967C-65.609 141.091 -164.678 53.4271 -237.014 -10.5874C-291.199 -58.5335 -327.063 -90.2269 -335.393 -108.384C-340.325 -115.733 -341.356 -120.973 -338.561 -124.409C-328.51 -136.79 -268.06 -114.199 -173.766 -73.7461C-143.944 -60.927 -66.8664 -27.0687 4.57732 10.4314C83.9131 52.0694 130.694 84.69 143.757 107.448L143.75 107.452ZM-330.229 -107.449C-319.954 -89.5349 -283.611 -57.3744 -234.29 -13.7303C-161.866 50.3563 -62.679 138.13 36.1483 251.212C64.6438 283.538 100.181 310.499 109.272 306.674C110.797 306.034 111.42 304.063 111.117 300.813C107.913 258.069 120.785 222.173 132.145 190.509C143.631 158.474 152.708 133.172 141.369 111.849L141.32 111.765C140.954 110.981 140.532 110.183 140.071 109.378C108.453 54.2226 -127.713 -49.4076 -175.348 -69.8762C-207.54 -83.6895 -244.492 -99.349 -275.608 -110.341C-322.212 -126.803 -333.013 -124.533 -335.305 -121.71C-336.743 -119.942 -335.514 -115.991 -331.856 -110.557L-331.753 -110.41L-331.678 -110.247C-331.279 -109.36 -330.792 -108.43 -330.237 -107.464L-330.229 -107.449Z"
        fill="url(#paint11_linear_1732_168)"
      />
      <path
        d="M130.236 108.442C130.814 109.451 131.327 110.442 131.789 111.423C143.354 133.619 133.557 159.124 122.21 188.649C111.714 215.962 99.8201 246.919 101.359 282.838C101.735 288.6 99.2618 290.852 97.1272 291.726C85.3826 296.512 50.494 268.426 24.9035 239.66C-65.4243 137.092 -156.616 56.5831 -223.196 -2.19695C-273.772 -46.8422 -307.243 -76.3961 -315.883 -93.7326C-321.484 -101.544 -322.803 -107.126 -319.943 -110.785C-311.413 -121.688 -264.089 -108.12 -166.5 -66.7753C-98.1642 -37.769 99.7796 55.3762 130.244 108.457L130.236 108.442ZM-311.22 -93.8322C-301.491 -76.87 -267.232 -46.6314 -220.476 -5.347C-153.805 53.5122 -62.5024 134.117 27.9966 236.888C51.6699 263.496 86.3084 291.547 95.4772 287.808C95.9601 287.609 97.4264 287.012 97.1696 282.978C95.5891 246.139 107.65 214.751 118.297 187.062C129.288 158.468 138.771 133.776 128.063 113.283L128.01 113.191C118.102 91.7866 73.2978 58.8203 -1.49542 17.916C-66.7762 -17.7867 -136.919 -49.6865 -168.064 -62.9062C-207.147 -79.4622 -237.623 -91.3224 -261.256 -99.1608C-304.374 -113.467 -314.461 -110.929 -316.641 -108.15C-318.157 -106.215 -316.651 -101.908 -312.414 -96.0259L-312.311 -95.8785L-312.222 -95.7232C-311.935 -95.1101 -311.599 -94.4774 -311.231 -93.8353L-311.22 -93.8322Z"
        fill="url(#paint12_linear_1732_168)"
      />
      <path
        d="M116.683 109.335C117.375 110.541 117.979 111.723 118.503 112.875C129.468 134.345 119.599 157.965 108.176 185.309C98.4318 208.634 87.3944 235.067 87.4206 265.13C87.564 270.135 85.2526 272.109 83.2872 272.877C78.0385 274.917 68.4091 270.745 54.6509 260.479C42.5074 251.411 28.6965 238.603 16.7663 225.329C-65.218 133.082 -148.51 59.7524 -209.323 6.21007C-254.639 -33.6847 -287.454 -62.5736 -296.378 -79.1234C-302.72 -87.4591 -304.369 -93.4245 -301.421 -97.3301C-298.291 -101.479 -289.126 -105.449 -245.771 -92.1082C-216.635 -83.1441 -182.631 -69.6175 -159.24 -59.8469C-124.223 -45.1932 -60.6054 -14.5862 -3.67086 17.9945C28.2351 36.2534 54.5884 53.1249 74.6732 68.145C96.064 84.1453 110.167 97.9744 116.679 109.327L116.683 109.335ZM-292.276 -80.298C-283.081 -64.2669 -250.871 -35.9046 -206.598 3.06712C-145.698 56.6815 -62.296 130.107 19.8623 222.545C46.7208 252.417 74.676 271.681 81.7146 268.935C82.1142 268.783 83.33 268.309 83.2342 265.1C83.2036 234.132 94.4192 207.29 104.312 183.6C115.334 157.204 124.852 134.399 114.759 114.689L114.706 114.597C114.222 113.497 113.647 112.383 112.992 111.24C84.722 61.9539 -90.3558 -26.4935 -160.774 -55.9574C-238.888 -88.5765 -291.5 -103.439 -298.061 -94.7374C-299.665 -92.6033 -297.86 -87.904 -292.968 -81.5045L-292.862 -81.3682L-292.773 -81.213C-292.621 -80.9158 -292.454 -80.6084 -292.276 -80.298V-80.298Z"
        fill="url(#paint13_linear_1732_168)"
      />
      <path
        d="M103.067 110.085C103.893 111.525 104.613 112.94 105.227 114.331C115.62 135.172 105.563 156.879 93.9282 182.015C84.9915 201.32 74.8597 223.201 73.4948 247.462C73.4172 251.713 71.2633 253.402 69.4699 254.053C58.2893 258.128 27.1754 231.34 8.65459 211.011C-64.9661 129.121 -140.321 62.9871 -195.343 14.7068C-237.252 -22.0628 -267.564 -48.6686 -276.846 -64.4827C-284.031 -73.4068 -286.038 -79.7869 -282.98 -83.9978C-271.176 -100.225 -179.711 -64.2975 -151.961 -52.9012C-121.309 -40.2885 -63.1151 -11.5337 -7.77643 21.7848C34.8564 47.4569 87.5336 83.0038 103.063 110.078L103.067 110.085ZM-278.043 -73.4065C-276.947 -71.4943 -275.436 -69.3401 -273.515 -66.9689L-273.329 -66.6914C-264.507 -51.5044 -234.356 -25.0506 -192.637 11.5646C-137.534 59.9209 -62.0619 126.147 11.7178 208.218C36.1335 235.015 61.5532 252.435 67.9648 250.101C68.3229 249.972 69.2562 249.63 69.3024 247.229C70.7218 222.087 81.0302 199.825 90.121 180.18C101.312 156.016 110.974 135.141 101.464 116.127L101.407 116.028C80.6433 68.4615 -88.7804 -22.3537 -153.479 -48.9834C-221.354 -76.8608 -273.216 -90.2053 -279.586 -81.4429C-280.728 -79.8794 -280.171 -77.1 -278.046 -73.3955L-278.043 -73.4065Z"
        fill="url(#paint14_linear_1732_168)"
      />
      <path
        d="M89.4212 110.752C90.4088 112.473 91.2537 114.154 91.9449 115.792C101.819 136.161 90.8847 156.851 79.3107 178.764C70.8671 194.751 62.132 211.287 59.544 229.846C59.2212 233.526 56.9562 234.828 55.6266 235.264C51.104 236.762 43.0642 233.296 31.7233 224.988C21.8145 217.719 10.4489 207.415 0.522747 196.706C-64.6779 125.207 -132.061 66.3141 -181.262 23.3163C-219.824 -10.3867 -247.719 -34.7635 -257.331 -49.8395C-265.463 -59.4055 -267.867 -66.271 -264.677 -70.828C-260.164 -77.257 -244.714 -77.0749 -217.431 -70.2566C-196.72 -65.0807 -170.21 -56.216 -144.702 -45.9425C-86.4894 -22.4418 60.9055 61.0692 89.4034 110.752L89.4212 110.752ZM-259.719 -60.1831C-258.42 -57.918 -256.531 -55.3139 -254.068 -52.4278L-253.886 -52.1753C-244.687 -37.6418 -216.947 -13.3919 -178.541 20.1662C-129.264 63.2329 -61.7748 122.215 3.58078 193.888C25.5457 217.596 48.4517 233.178 54.2514 231.266C54.5679 231.161 55.2396 230.939 55.3844 229.286C58.084 209.897 66.9922 193.039 75.602 176.733C87.1535 154.861 97.1306 135.976 88.1601 117.535L88.1034 117.436C68.3011 70.3606 -88.1153 -18.5843 -146.177 -42.0301C-205.522 -65.9361 -254.98 -77.2497 -261.234 -68.3475C-262.399 -66.6961 -261.855 -63.8908 -259.726 -60.1792L-259.719 -60.1831Z"
        fill="url(#paint15_linear_1732_168)"
      />
      <path
        d="M75.6465 111.145C76.8406 113.227 77.856 115.27 78.667 117.26C88.1107 137.406 76.4219 155.934 64.0595 175.545C56.7047 187.204 49.1028 199.264 45.5807 212.256C45.1225 214.451 43.7761 215.963 41.7717 216.519C31.9294 219.245 6.27793 197.036 -7.58019 182.403C-64.2953 121.361 -123.626 69.7676 -166.954 32.097C-202.267 1.38532 -227.807 -20.8213 -237.791 -35.2008C-246.997 -45.4863 -249.839 -52.9077 -246.478 -57.8705C-235.932 -73.4599 -164.169 -49.5216 -137.406 -38.9852C-108.169 -27.4485 -58.2307 0.677773 -15.9586 29.3997C12.2893 48.5941 59.8153 83.5297 75.6575 111.149L75.6465 111.145ZM-241.51 -47.0159C-239.996 -44.3768 -237.686 -41.31 -234.624 -37.8933L-234.456 -37.6801C-224.842 -23.7545 -198.107 -0.504796 -164.256 28.9225C-120.856 66.655 -61.4302 118.334 -4.56133 179.533C15.5077 200.735 35.3381 213.9 40.5818 212.443C40.9452 212.34 41.3148 212.168 41.4905 211.274L41.5211 211.135C45.2006 197.52 52.978 185.174 60.5123 173.236C72.2629 154.607 83.3611 137.003 74.8677 118.946L74.811 118.847C55.7123 71.8294 -86.6694 -14.4626 -138.871 -35.0697C-183.652 -52.7007 -236.199 -65.5196 -243.006 -55.4527C-244.21 -53.6765 -243.671 -50.784 -241.51 -47.0159V-47.0159Z"
        fill="url(#paint16_linear_1732_168)"
      />
      <path
        d="M61.7287 111.211C63.2182 113.808 64.4472 116.318 65.3895 118.73C74.5674 139.149 60.8367 155.992 47.5517 172.283C41.5932 179.588 35.4348 187.136 31.5216 194.852C30.8667 196.449 29.5627 197.505 27.8239 197.836C18.8189 199.574 -3.13932 181.003 -15.7007 168.102C-63.8085 117.616 -112.885 75.2257 -152.313 41.1686C-183.33 14.3785 -207.842 -6.80352 -218.274 -20.5555C-228.696 -31.6482 -232.028 -39.6993 -228.469 -45.1669C-219.144 -59.4755 -163.215 -44.701 -130.157 -32.0282C-81.2604 -13.2305 34.248 63.3503 61.7148 111.219L61.7287 111.211ZM-223.426 -33.9244C-221.685 -30.8901 -218.916 -27.3263 -215.164 -23.3469L-215.016 -23.1689C-204.901 -9.77908 -180.504 11.2995 -149.612 37.983C-110.112 72.102 -60.9554 114.569 -12.7129 165.193C5.00744 183.389 22.113 194.621 26.9597 193.688C27.4069 193.603 27.5438 193.441 27.6574 193.143L27.7422 192.955C31.8614 184.813 38.1832 177.069 44.2942 169.566C57.3627 153.538 69.706 138.407 61.5577 120.359L61.5149 120.253C60.6408 118.008 59.4869 115.661 58.0945 113.233C31.5495 66.9557 -84.3873 -9.97162 -131.575 -28.111C-170.534 -43.0498 -218.463 -52.7642 -224.945 -42.8165C-226.216 -40.8708 -225.675 -37.8299 -223.437 -33.9276L-223.426 -33.9244Z"
        fill="url(#paint17_linear_1732_168)"
      />
      <path
        d="M47.6689 110.965C49.543 114.232 51.0282 117.318 52.1165 120.208C61.415 141.99 43.6923 156.102 28.0533 168.547C24.114 171.687 20.3929 174.647 17.2155 177.626C16.6 178.33 15.5055 179.128 13.6538 179.215C5.97148 179.576 -11.1451 166.292 -23.8027 153.8C-63.064 114.064 -104.013 79.0061 -136.917 50.8338C-165.288 26.5447 -187.704 7.35269 -198.731 -5.93167C-210.534 -17.9257 -214.424 -26.7085 -210.61 -32.7883C-206.675 -39.0581 -194.861 -40.9753 -175.499 -38.4925C-159.684 -36.4593 -140.023 -31.4531 -122.887 -25.1001C-99.6388 -16.4507 -59.9557 8.50567 -24.1384 37.0183C10.8905 64.8931 36.3185 91.1774 47.6608 110.951L47.6689 110.965ZM-205.497 -20.9298C-203.502 -17.4507 -200.22 -13.3633 -195.719 -8.79838L-195.595 -8.6627C-184.823 4.33633 -162.499 23.4491 -134.241 47.6528C-101.28 75.8768 -60.2547 111.004 -20.879 150.844C-6.90922 164.63 8.13882 175.252 13.3715 175.008C13.5685 174.999 13.9146 174.962 14.0376 174.808L14.2287 174.597C17.551 171.47 21.3679 168.437 25.406 165.213C40.6113 153.117 56.3283 140.6 48.2538 121.752L48.219 121.659C32.1407 78.6583 -76.1841 -3.26168 -124.262 -21.1412C-158.204 -33.7353 -200.827 -40.4085 -207.048 -30.4865C-208.417 -28.3084 -207.861 -25.051 -205.501 -20.9368L-205.497 -20.9298Z"
        fill="url(#paint18_linear_1732_168)"
      />
      <path
        d="M32.9349 109.25C35.4283 113.597 37.4212 117.76 38.8196 121.67C41.5586 128.543 41.5999 134.747 38.9317 140.103C33.4217 151.183 17.3848 156.56 2.38811 160.356C-5.95923 163.32 -29.2544 141.953 -31.9023 139.499C-61.7006 110.99 -92.4717 84.9959 -119.619 62.0535C-145.14 40.4896 -167.18 21.8715 -179.237 8.64816C-192.375 -4.1036 -197.025 -14.0354 -192.959 -20.7812C-184.523 -34.7823 -141.829 -27.4865 -115.632 -18.1442C-77.5605 -4.54457 6.3386 62.899 32.9308 109.243L32.9349 109.25ZM-187.734 -8.07055C-185.456 -4.09769 -181.599 0.56099 -176.263 5.74713C-164.306 18.8592 -142.369 37.395 -116.958 58.8625C-89.762 81.8425 -58.9376 107.881 -29.063 136.459C-13.6233 150.823 -1.52291 157.364 0.928496 156.386L1.18556 156.306C15.3094 152.734 30.3967 147.783 35.1818 138.16C37.3282 133.849 37.2443 128.931 34.9236 123.14L34.8969 123.061C28.0069 103.749 3.39672 74.1976 -30.9284 44.0091C-62.8728 15.9218 -96.6374 -6.91693 -116.945 -14.1738C-146.106 -24.5671 -183.268 -28.6395 -189.348 -18.5361C-190.836 -16.056 -190.261 -12.492 -187.724 -8.06748L-187.734 -8.07055Z"
        fill="url(#paint19_linear_1732_168)"
      />
      <path
        d="M17.5557 106.147C21.0035 112.158 23.7101 117.87 25.4888 123.116C28.0556 130.168 27.6339 135.708 24.2253 139.594C16.7885 148.063 -2.09589 145.09 -12.6918 142.56C-20.6285 141.113 -35.5982 128.908 -39.9885 125.208C-57.7182 109.856 -76.1028 94.7073 -93.8807 80.056C-119.942 58.582 -144.555 38.3061 -159.666 23.2933C-174.602 9.38701 -179.953 -1.57536 -175.535 -9.24023C-167.399 -23.3411 -130.659 -18.7553 -108.384 -11.2152C-74.9388 0.127823 -8.10112 61.3986 17.5586 106.136L17.5557 106.147ZM-170.173 4.62332C-167.571 9.15986 -163.082 14.4406 -156.815 20.2752C-141.797 35.1908 -117.252 55.4119 -91.274 76.8208C-73.4771 91.4894 -55.0664 106.652 -37.3176 122.021C-26.4828 131.146 -16.5029 137.596 -11.9124 138.444C4.44208 142.337 16.7299 141.702 21.0621 136.769C23.3937 134.11 23.5579 129.979 21.5509 124.478L21.5266 124.436C14.9913 105.086 -6.22795 76.3984 -35.2175 47.6745C-63.2599 19.8815 -91.7853 -1.14959 -109.655 -7.20404C-134.203 -15.5218 -166.045 -17.2184 -171.91 -7.05039C-173.567 -4.17607 -172.947 -0.198558 -170.175 4.63431L-170.173 4.62332Z"
        fill="url(#paint20_linear_1732_168)"
      />
      <path
        d="M0.42367 99.1725C5.63777 108.264 9.69171 116.902 12.1471 124.576C14.204 131.027 13.6109 135.354 10.3886 137.433C3.05098 142.163 -17.6887 132.358 -53.0293 107.463C-80.1939 88.3268 -113.561 61.6875 -140.116 37.9341C-157.104 22.7309 -163.264 10.549 -158.416 1.73477C-151.653 -10.5578 -123.358 -11.2971 -101.165 -4.26945C-73.3529 4.53646 -23.9155 56.7322 0.42367 99.1725V99.1725ZM-137.37 34.8165C-66.8261 97.937 -1.25261 139.892 8.08025 133.872C10.0034 132.633 8.95383 128.274 8.16159 125.788C2.02092 106.567 -16.2485 78.0232 -39.5232 51.2984C-63.36 23.9295 -86.8504 4.6546 -102.349 -0.252342C-122.512 -6.63645 -149.199 -6.24739 -154.745 3.83141C-158.417 10.5025 -152.243 21.5082 -137.374 34.8094L-137.37 34.8165Z"
        fill="url(#paint21_linear_1732_168)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1732_168"
          x1="-93.1805"
          y1="-107.795"
          x2="415.325"
          y2="488.958"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1732_168"
          x1="-92.4745"
          y1="-100.772"
          x2="394.962"
          y2="471.497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1732_168"
          x1="-91.8183"
          y1="-93.7778"
          x2="374.561"
          y2="454.035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1732_168"
          x1="-91.1676"
          y1="-86.7923"
          x2="354.175"
          y2="436.586"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1732_168"
          x1="-90.5385"
          y1="-79.8282"
          x2="333.791"
          y2="419.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1732_168"
          x1="-89.9235"
          y1="-72.9208"
          x2="313.432"
          y2="401.663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1732_168"
          x1="-89.3436"
          y1="-66.0242"
          x2="293.067"
          y2="384.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1732_168"
          x1="-88.7788"
          y1="-59.1645"
          x2="272.732"
          y2="366.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1732_168"
          x1="-88.2286"
          y1="-52.3519"
          x2="252.418"
          y2="349.265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1732_168"
          x1="-87.7251"
          y1="-45.6021"
          x2="232.115"
          y2="331.782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1732_168"
          x1="-87.2462"
          y1="-38.903"
          x2="211.852"
          y2="314.296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1732_168"
          x1="-86.7985"
          y1="-32.2577"
          x2="191.612"
          y2="296.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1732_168"
          x1="-86.4913"
          y1="-25.6337"
          x2="171.277"
          y2="279.417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1732_168"
          x1="-86.2832"
          y1="-19.1591"
          x2="150.978"
          y2="262.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1732_168"
          x1="-86.083"
          y1="-12.6654"
          x2="130.682"
          y2="244.647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1732_168"
          x1="-86.0214"
          y1="-6.35767"
          x2="110.441"
          y2="227.29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1732_168"
          x1="-86.0111"
          y1="-0.132746"
          x2="90.2709"
          y2="209.973"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1732_168"
          x1="-86.1421"
          y1="5.90857"
          x2="70.1939"
          y2="192.703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1732_168"
          x1="-86.3865"
          y1="11.7554"
          x2="50.3014"
          y2="175.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1732_168"
          x1="-85.9172"
          y1="18.7163"
          x2="33.8901"
          y2="161.793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1732_168"
          x1="-84.4324"
          y1="27.3953"
          x2="22.7082"
          y2="153.294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1732_168"
          x1="-82.7114"
          y1="36.1472"
          x2="12.9825"
          y2="145.798"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
