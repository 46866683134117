import { Grid, Typography, Container, useMediaQuery } from "@mui/material";
import {
  FooterBg,
  FooterBgMobile,
  FooterLogo,
  FooterLogoMobile,
} from "../assets/images";
import { Img, LaunchButton, NavigationsLinks } from "../shared";
import { socialIcons, links } from "../constants";

export const Footer = () => {
  const matches = useMediaQuery("(max-width: 600px)");

  return (
    <Grid
      container
      sx={{
        backgroundImage: {
          xs: `url(${FooterBgMobile})`,
          sm: `url(${FooterBg})`,
        },
        backgroundRepeat: "no-repeat !important",
        backgroundSize: "cover",
        "@media (max-width:376px)": {
          backgroundSize: "unset",
        },
        backgroundPosition: "center !important",
        position: "relative",
        mt: { xs: 7.5, sm: 10 },
        "&:before": {
          content: '""',
          position: "absolute",
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
        },
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          item
          xs={12}
          sm={11}
          py={10}
          justifyContent={{ xs: "center", sm: "space-between" }}
          position={"relative"}
        >
          <Grid item sm={3}>
            <Grid item mb={3}>
              {matches ? (
                <Img src={FooterLogoMobile} />
              ) : (
                <Img src={FooterLogo} />
              )}
            </Grid>
            {matches ? (
              ""
            ) : (
              <Grid item>
                <Typography
                  variant="h3"
                  color="neutralGrey"
                  lineHeight="53px"
                  fontWeight="300"
                  fontFamily={"AcehLight"}
                >
                  Building Decentralized{" "}
                  <Typography
                    variant="inherit"
                    fontWeight={700}
                    color="neutralWhite"
                  >
                    Solutions.
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            sm={4}
            lg={2}
            rowSpacing={{ xs: 3, sm: 2 }}
            display="flex"
            flexDirection="column"
            alignItems={{ xs: "center", sm: "flex-start" }}
            pt={{ xs: 6, sm: 4 }}
          >
            <NavigationsLinks links={links} />
            <Grid item mt={2}>
              <LaunchButton
                sx={(theme) => ({
                  lineHeight: 1,
                  py: 2,
                  [theme.breakpoints.down("md")]: {
                    fontSize: "16px",
                    px: 1.25,
                  },
                })}
                disabled
              >
                NFT Suite coming soon
              </LaunchButton>
            </Grid>
          </Grid>
          <Grid
            item
            container
            columnSpacing={3}
            mt={{ xs: 15.5, sm: 3.5 }}
            justifyContent={{ xs: "center", sm: "flex-start" }}
          >
            {socialIcons.map((icon, i) => (
              <Grid item key={`social-icon${i}`}>
                <a href={icon.link} target="_blank" rel="noreferrer">
                  {icon.element}
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
