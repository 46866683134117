import { SvgIcon } from "@mui/material";
export const TwitterIcon = () => {
  return (
    <SvgIcon
      width="32"
      height="27"
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.052 26.9999C22.128 26.9999 28.734 16.9939 28.734 8.33192C28.734 8.05192 28.734 7.76792 28.722 7.48792C30.0081 6.55689 31.1181 5.40422 32 4.08392C30.7986 4.61362 29.5255 4.96273 28.222 5.11992C29.5951 4.2992 30.6234 3.00786 31.116 1.48592C29.826 2.25017 28.414 2.78667 26.942 3.07192C25.9524 2.01797 24.6428 1.31976 23.2162 1.08544C21.7895 0.851116 20.3254 1.09376 19.0507 1.7758C17.7759 2.45783 16.7616 3.54118 16.1649 4.85804C15.5682 6.1749 15.4224 7.65179 15.75 9.05992C13.1395 8.92902 10.5856 8.25086 8.25409 7.06941C5.92254 5.88796 3.86537 4.22961 2.216 2.20192C1.37868 3.64809 1.12315 5.35872 1.50129 6.98645C1.87944 8.61418 2.86291 10.037 4.252 10.9659C3.21108 10.9306 2.19304 10.651 1.28 10.1499V10.2399C1.28179 11.7549 1.80661 13.2228 2.76575 14.3954C3.72488 15.5681 5.0595 16.3737 6.544 16.6759C5.98053 16.8312 5.39846 16.9085 4.814 16.9059C4.40197 16.9072 3.99076 16.869 3.586 16.7919C4.00557 18.096 4.82255 19.2363 5.92249 20.0529C7.02244 20.8695 8.35027 21.3216 9.72 21.3459C7.39311 23.1735 4.5188 24.1647 1.56 24.1599C1.03864 24.1621 0.517646 24.1321 0 24.0699C3.00303 25.9844 6.4906 27.001 10.052 26.9999Z"
        fill="#EEF0F2"
      />
    </SvgIcon>
  );
};
