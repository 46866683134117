import { Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  border: "1.7193px solid",
  color: theme.palette.neutralWhite,
  padding: "8px 32px",
  borderRadius: "0px",
  backgroundColor: theme.palette.main,
  fontSize: "14px",
  height: "42px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: theme.palette.main,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 16px",
  },
}));
export const SecondaryButton = ({ children, ...props }) => {
  return (
    <StyledButton disableRipple {...props}>
      {children}
    </StyledButton>
  );
};
