import { SvgIcon } from "@mui/material";
export const HeaderDash = ({ ...props }) => {
  return (
    <SvgIcon
      width="840"
      height="733"
      viewBox="0 0 840 733"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="103"
        y="69"
        width="1064"
        height="602"
        rx="20"
        fill="url(#paint0_linear_1868_194)"
      />
      <rect x="398" y="535" width="336" height="88" rx="10" fill="#1B1B1F" />
      <rect x="776" y="535" width="336" height="88" rx="10" fill="#1B1B1F" />
      <rect
        x="646.892"
        y="116"
        width="215.892"
        height="130"
        rx="10"
        fill="#313236"
      />
      <rect x="398" y="290" width="714" height="209" rx="10" fill="#313236" />
      <rect
        x="398"
        y="116"
        width="215.892"
        height="130"
        rx="10"
        fill="#313236"
      />
      <rect x="157" y="116" width="216" height="507" rx="10" fill="#131415" />
      <g filter="url(#filter0_b_1868_194)">
        <rect
          y="150"
          width="261"
          height="150"
          rx="10"
          fill="url(#paint1_linear_1868_194)"
        />
      </g>
      <g filter="url(#filter1_b_1868_194)">
        <rect
          x="332"
          y="607"
          width="423"
          height="126"
          rx="10"
          fill="url(#paint2_linear_1868_194)"
          fillOpacity="0.67"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1868_194"
          x="-54"
          y="96"
          width="369"
          height="258"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="27" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1868_194"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1868_194"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_1868_194"
          x="278"
          y="553"
          width="531"
          height="234"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="27" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1868_194"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1868_194"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1868_194"
          x1="-19"
          y1="163.5"
          x2="1075.5"
          y2="424.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#323336" />
          <stop offset="1" stopColor="#2B2D33" stopOpacity="0.71" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1868_194"
          x1="-26"
          y1="150"
          x2="278"
          y2="278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#474952" />
          <stop offset="1" stopColor="#F7F7F7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1868_194"
          x1="289.862"
          y1="607"
          x2="569.814"
          y2="1044.28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.895833" stopColor="white" stopOpacity="0.40875" />
          <stop offset="1" stopColor="#080B18" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
