import { DiscordIcon, MediumIcon, TwitterIcon } from "../assets/icons";

import { Aslab, Astro, Tenk, Wgmi } from "../assets/images";

export const links = [
  {
    name: "Home",
    id: "home",
  },
  {
    name: "Services",
    id: "services",
  },
  {
    name: "About Us",
    id: "about-us",
  },
  {
    name: "Partners",
    id: "partners",
  },
];

export const socialIcons = [
  {
    element: <MediumIcon />,
    link: "https://medium.com/@theomnidao"
  },
  {
    element: <TwitterIcon />,
    link: "https://twitter.com/theomnidao"
  },
  {
    element: <DiscordIcon sx={{ fill: "#FFFFFF" }} />,
    link: "https://discord.gg/AbEkSs4Fte"
  }
];

export const partners = [
  {
    logo: Astro,
    alt: "Partnering with Astro DAO"
  },
  {
    logo: Tenk,
    alt: "Partnering with Tenk DAO"
  },
  {
    logo: Wgmi,
    alt: "Partnering with WGMI Solutions" 
  },
  { 
    logo: Aslab,
    alt: "Partnering with Antisocial Labs"
  }];
