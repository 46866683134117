import { SvgIcon } from "@mui/material";
import React from "react";

export const CloseIcon = ({ ...props }) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="18"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.72498 0.282509C9.43248 -0.00999128 8.95998 -0.00999128 8.66748 0.282509L4.99998 3.94251L1.33248 0.275009C1.03998 -0.0174914 0.567478 -0.0174914 0.274978 0.275009C-0.017522 0.567508 -0.017522 1.04001 0.274978 1.33251L3.94248 5.00001L0.274978 8.66751C-0.017522 8.96001 -0.017522 9.43251 0.274978 9.72501C0.567478 10.0175 1.03998 10.0175 1.33248 9.72501L4.99998 6.05751L8.66748 9.72501C8.95998 10.0175 9.43248 10.0175 9.72498 9.72501C10.0175 9.43251 10.0175 8.96001 9.72498 8.66751L6.05748 5.00001L9.72498 1.33251C10.01 1.04751 10.01 0.567509 9.72498 0.282509V0.282509Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};
