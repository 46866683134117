import { Grid, Box, styled, Typography, useMediaQuery } from "@mui/material";
import { ServicesTextIcon, SolutionBgIcon } from "../assets/icons";
import { ServiceIcon } from "../assets/images";
import React from "react";
import { ContactCard, Img, SolutionCard } from "../shared";
import { Container } from "@mui/system";
const BoxStyled = styled(Box)(() => ({
  width: "24px",
  height: "14px",
  borderRadius: "6px",
  marginRight: "12px",
}));
const boxBg = ["dark", "light", "neutralGrey", "neutralWhite"];
export const Web3Solutions = () => {
  const matches = useMediaQuery("(max-width: 992px)");

  return (
    <Grid
      container
      sx={{
        position: "relative",
        pt: 9,
      }}
      id="services"
    >
      <Container maxWidth="xl">
        <Grid pt={{ xs: 0, sm: 28 }} pb={{ x: 6, sm: 20 }} item>
          <Grid container item display="flex" mb={{ xs: 2, sm: 3 }}>
            {boxBg.map((bg, i) => (
              <BoxStyled bgcolor={bg} key={`box${i}`}></BoxStyled>
            ))}
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              sx={(theme) => ({
                mt: "24px",
                lineHeight: "60.84px",
                fontFamily: "AcehLight",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "36px",
                  lineHeight: "36px",
                },
              })}
            >
              We build
            </Typography>
            <Typography
              variant="h1"
              sx={(theme) => ({
                fontFamily: "AcehMedium",
                lineHeight: "60.84px",
                whiteSpace: "nowrap",
                letterSpacing: "unset",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "40px",
                  lineHeight: "30px",
                },
              })}
            >
              WEB3 solutions
            </Typography>
          </Grid>

          <Grid
            container
            item
            sx={{
              display: "flex",
              mt: { xs: 6, sm: 20 },
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
            columnSpacing={4}
          >
            <Grid item xs={12} sm={6} mb={{ xs: 5, sm: 0 }}>
              <SolutionCard />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ContactCard />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {matches ? (
        <Img
          src={ServiceIcon}
          sx={{
            position: "absolute",
            top: "60px",
            right: 0,
            zIndex: -1,
            display: { xs: "block", sm: "none" },
          }}
        />
      ) : (
        <SolutionBgIcon
          sx={{ position: "absolute", zIndex: -1, top: "-28px" }}
        />
      )}
      <ServicesTextIcon
        sx={{
          position: "absolute",
          bottom: { xs: "-55px", sm: "0px" },
          zIndex: -1,
          width: "100%",
        }}
      />
    </Grid>
  );
};
