import { SvgIcon } from "@mui/material";
export const MediumIcon = () => {
  return (
    <SvgIcon
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.82935 4.68525C3.84569 4.52294 3.82381 4.35906 3.76547 4.20671C3.70712 4.05437 3.61393 3.9178 3.49335 3.80792L1.00269 0.785252V0.333252H8.73335L14.7094 13.5399L19.9627 0.333252H27.3334V0.785252L25.204 2.83992C25.1138 2.90958 25.0442 3.00244 25.0026 3.10859C24.961 3.21473 24.9491 3.33017 24.968 3.44258V18.5546C24.9491 18.667 24.961 18.7824 25.0026 18.8886C25.0442 18.9947 25.1138 19.0876 25.204 19.1573L27.284 21.2133V21.6666H16.8254V21.2146L18.98 19.1079C19.192 18.8946 19.192 18.8319 19.192 18.5066V6.29059L13.2027 21.6159H12.3947L5.42135 6.29059V16.5626C5.36402 16.9933 5.50669 17.4293 5.80802 17.7413L8.60935 21.1653V21.6159H0.666687V21.1653L3.46669 17.7413C3.61484 17.5867 3.72479 17.3996 3.78776 17.1949C3.85073 16.9903 3.86497 16.7737 3.82935 16.5626V4.68525Z"
        fill="white"
      />
    </SvgIcon>
  );
};
