import { SvgIcon } from "@mui/material";
export const HeaderBottomLinesIcon = ({ ...props }) => {
  return (
    <SvgIcon
      width="553"
      height="1214"
      viewBox="0 0 553 1214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M349.403 915.444C348.07 914.341 346.773 913.221 345.502 912.07C300.955 871.724 295.279 804.198 328.645 711.365C375.198 581.829 364.293 519.422 357.076 478.129C351.141 444.165 347.87 425.458 384.362 397.445L385.043 398.353L384.362 397.445C420.797 369.473 452.537 355.268 493.913 348.415C534.888 341.628 584.288 342.017 659.061 342.611C710.993 343.024 775.623 343.534 856.835 341.653L856.942 341.654C874.37 342.86 891.563 343.981 908.192 345.067C1055.83 354.695 1172.45 362.294 1204.42 412.201C1215.34 429.243 1216.06 450.276 1206.6 476.495C1193.38 513.132 1188.47 545.762 1184.14 574.556C1177.66 617.666 1172.06 654.897 1142.67 693.821C1110.66 736.222 1052.45 776.555 959.478 820.761C894.904 851.463 830.747 877.688 768.801 898.703C705.569 920.155 646.208 935.638 592.367 944.723C479.368 963.787 395.548 953.653 349.401 915.46L349.403 915.444ZM856.768 343.956C775.546 345.835 710.909 345.325 658.975 344.917C584.322 344.328 535.003 343.936 494.223 350.693C453.262 357.476 421.829 371.55 385.728 399.263C350.388 426.389 353.381 443.527 359.345 477.66C362.959 498.347 367.459 524.087 365.273 561.376C362.693 605.433 351.422 654.73 330.808 712.09C297.791 803.96 303.263 870.658 347.075 910.337C348.319 911.466 349.602 912.575 350.909 913.656C396.534 951.429 479.716 961.384 592.056 942.433C645.777 933.37 705.014 917.919 768.127 896.508C829.987 875.522 894.053 849.332 958.549 818.669C1051.16 774.641 1109.09 734.537 1140.85 692.456C1169.87 654.018 1175.43 617.064 1181.86 574.273C1186.21 545.377 1191.13 512.62 1204.43 475.764C1213.63 450.267 1212.99 429.897 1202.47 413.489C1171.1 364.521 1054.98 356.954 907.968 347.368C891.355 346.284 874.177 345.164 856.759 343.961L856.768 343.956Z"
        fill="url(#paint0_linear_1868_89)"
      />
      <path
        d="M371.263 906.75C370.211 905.88 369.18 904.997 368.17 904.097C346.904 885.201 334.246 859.806 330.545 828.626C326.635 795.678 332.702 755.803 348.583 710.114C391.043 586.618 380.445 524.421 373.426 483.267C367.43 448.096 364.129 428.723 399.889 400.985C434.961 373.919 465.58 360.233 505.576 353.743C545.138 347.326 592.777 347.938 664.893 348.862C714.497 349.5 776.235 350.29 853.538 348.885L853.642 348.889C869.651 350.049 885.469 351.138 900.764 352.189C1042.96 361.965 1155.27 369.683 1186.54 418.096C1197.18 434.586 1198.07 454.864 1189.25 480.108C1177.47 515.538 1173.52 545.937 1169.69 575.337C1164.16 617.892 1159.39 654.641 1131.73 692.965C1101.57 734.757 1046.2 774.242 957.463 817.227C834.104 876.99 709.591 919.295 606.866 936.359C497.84 954.468 416.529 944.22 371.266 906.759L371.263 906.75ZM853.452 351.19C776.14 352.599 714.396 351.803 664.785 351.167C592.794 350.242 545.23 349.634 505.87 356.02C466.296 362.438 435.986 375.99 401.258 402.794C384.821 415.544 376.187 426.961 373.256 439.819C370.457 452.099 372.77 465.651 375.696 482.81C382.752 524.199 393.411 586.744 350.757 710.812C319.98 799.378 326.363 863.817 369.729 902.353C370.722 903.233 371.737 904.108 372.768 904.962C417.516 942.002 498.192 952.074 606.559 934.078C709.07 917.053 833.354 874.82 956.516 815.15C1044.88 772.346 1099.96 733.097 1129.88 691.645C1157.18 653.813 1161.92 617.338 1167.41 575.105C1171.07 546.968 1175.22 515.08 1187.08 479.435L1187.09 479.421C1195.66 454.891 1194.84 435.257 1184.6 419.392C1153.94 371.91 1042.12 364.224 900.542 354.487C885.257 353.438 869.458 352.353 853.462 351.192L853.452 351.19Z"
        fill="url(#paint1_linear_1868_89)"
      />
      <path
        d="M393.103 898.041C392.36 897.425 391.631 896.805 390.905 896.175C369.854 877.853 357.077 853.29 352.931 823.172C348.548 791.327 353.793 752.865 368.519 708.86C406.921 591.423 396.61 529.513 389.788 488.541C383.717 452.083 380.371 432.001 415.413 404.516C483.448 351.709 534.443 352.638 670.736 355.121C718.009 355.98 776.848 357.052 850.232 356.114L850.332 356.115C864.924 357.225 879.367 358.275 893.333 359.287C1030.08 369.215 1138.08 377.06 1168.63 423.977C1179.01 439.911 1180.08 459.453 1171.9 483.723C1161.53 517.91 1158.32 547.459 1155.21 576.031C1150.64 618.042 1146.7 654.327 1120.78 692.064C1092.49 733.261 1039.95 771.907 955.439 813.693C839.089 871.221 720.461 911.81 621.408 927.989C516.339 945.148 437.502 934.787 393.107 898.043L393.103 898.041ZM850.14 358.42C776.743 359.359 717.898 358.289 670.622 357.426C601.283 356.162 555.476 355.328 517.527 361.342C479.335 367.394 450.149 380.423 416.788 406.317C382.866 432.925 386.131 452.521 392.055 488.088C398.917 529.278 409.284 591.527 370.7 709.517C356.085 753.184 350.878 791.287 355.209 822.781C359.279 852.345 371.807 876.445 392.441 894.405C435.921 932.214 514.959 943.039 621.1 925.701C719.93 909.559 838.323 869.042 954.467 811.618C1038.59 770.022 1090.84 731.63 1118.89 690.785C1144.47 653.543 1148.39 617.535 1152.92 575.841C1156.04 547.158 1159.26 517.498 1169.69 483.09L1169.71 483.054C1177.65 459.497 1176.67 440.595 1166.69 425.273C1136.74 379.267 1029.21 371.466 893.089 361.578C879.138 360.562 864.712 359.519 850.132 358.408L850.14 358.42Z"
        fill="url(#paint2_linear_1868_89)"
      />
      <path
        d="M414.926 889.316C414.524 888.983 414.121 888.65 413.725 888.31C392.861 870.569 379.949 846.831 375.341 817.768C370.468 787.012 374.88 749.948 388.454 707.611C422.832 596.258 412.801 534.713 406.163 493.991C403.137 475.419 400.744 460.75 403.399 447.323C406.227 433.031 414.721 420.919 430.935 408.05C496.242 357.047 545.355 358.231 676.615 361.391C721.551 362.474 777.471 363.822 846.932 363.342L847.03 363.347C860.216 364.4 873.283 365.405 885.921 366.376C1017.2 376.47 1120.89 384.441 1150.73 429.862C1160.83 445.246 1162.08 464.049 1154.55 487.341C1145.6 520.264 1143.11 548.92 1140.71 576.64C1137.12 618.131 1134.01 653.964 1109.84 691.128C1083.42 731.746 1033.72 769.572 953.42 810.169C844.049 865.459 731.322 904.333 636.011 919.629C534.873 935.857 458.482 925.376 414.926 889.322L414.926 889.316ZM846.821 365.651C777.35 366.129 721.424 364.781 676.485 363.695C609.796 362.089 565.733 361.031 529.191 366.669C492.379 372.352 464.314 384.862 432.319 409.847C399.088 436.222 402.409 456.582 408.434 493.547C415.108 534.485 425.195 596.349 390.647 708.238C377.186 750.237 372.799 786.937 377.616 817.333C382.136 845.856 394.796 869.135 415.244 886.527C415.637 886.857 416.029 887.188 416.424 887.515C459.471 923.147 535.237 933.461 635.713 917.337C730.791 902.082 843.27 863.288 952.43 808.103C1032.33 767.708 1081.74 730.15 1107.92 689.904C1131.76 653.239 1134.85 617.679 1138.41 576.507C1140.82 548.68 1143.32 519.905 1152.33 486.769L1152.35 486.714C1159.66 464.12 1158.5 445.951 1148.79 431.173C1119.54 386.651 1016.34 378.722 885.668 368.678C873.046 367.709 859.989 366.705 846.819 365.654L846.821 365.651Z"
        fill="url(#paint3_linear_1868_89)"
      />
      <path
        d="M436.719 880.573L436.652 880.518C415.944 863.353 402.868 840.44 397.788 812.417C392.404 782.739 395.973 747.06 408.392 706.366C438.794 601.129 429.027 540.055 422.563 499.642C416.479 461.595 412.804 438.627 446.457 411.583C509.042 362.378 556.276 363.82 682.524 367.674C725.11 368.975 778.107 370.591 843.63 370.574L843.728 370.579C855.506 371.571 867.117 372.519 878.513 373.446C1004.31 383.705 1103.67 391.802 1132.82 435.737C1142.66 450.571 1144.1 468.63 1137.2 490.955C1129.65 522.568 1127.88 550.305 1126.18 577.133C1123.57 618.126 1121.32 653.531 1098.91 690.14C1074.38 730.201 1027.51 767.22 951.409 806.64C848.981 859.692 742.183 896.846 650.694 911.253C553.465 926.567 479.475 915.958 436.729 880.575L436.719 880.573ZM843.507 372.884C777.971 372.902 724.968 371.287 682.378 369.983C556.75 366.147 509.746 364.714 447.853 413.373C415.296 439.536 418.684 460.728 424.835 499.204C431.334 539.82 441.146 601.199 410.593 706.958C398.276 747.312 394.732 782.621 400.05 811.936C405.036 839.427 417.855 861.894 438.151 878.717L438.218 878.773C459.658 896.52 489.348 908.015 526.476 912.949C561.984 917.667 603.677 916.329 650.399 908.97C741.649 894.598 848.19 857.529 950.398 804.591C1026.09 765.382 1072.66 728.647 1096.95 688.975C1119.06 652.87 1121.29 617.737 1123.88 577.057C1125.59 550.122 1127.36 522.271 1134.97 490.448L1134.99 490.373C1141.68 468.739 1140.34 451.3 1130.9 437.06C1102.34 394.024 1003.45 385.959 878.258 375.751C866.874 374.823 855.278 373.877 843.51 372.887L843.507 372.884Z"
        fill="url(#paint4_linear_1868_89)"
      />
      <path
        d="M458.508 871.826C438.607 855.353 425.744 833.6 420.272 807.139C414.355 778.526 417.063 744.204 428.328 705.125C454.806 606.042 445.667 547.953 438.992 505.538C432.757 465.906 428.993 441.982 461.98 415.121C521.845 367.704 567.216 369.412 688.466 373.977C728.692 375.494 778.757 377.376 840.322 377.806L840.413 377.811C850.794 378.736 861.031 379.623 871.103 380.496C991.403 390.928 1086.43 399.172 1114.89 441.602C1124.48 455.885 1126.09 473.211 1119.83 494.565C1113.66 524.824 1112.6 551.6 1111.58 577.489C1108.44 657.003 1105.96 719.811 949.38 803.107C853.87 853.913 753.03 889.346 665.444 902.875C620.842 909.762 580.781 910.881 546.376 906.2C510.272 901.289 481.11 890.051 459.706 872.806C459.305 872.48 458.9 872.15 458.501 871.82L458.508 871.826ZM840.192 380.117C778.609 379.689 728.543 377.801 688.311 376.284C567.687 371.743 522.552 370.043 463.388 416.904C448.33 429.164 440.509 441.196 438.045 455.897C435.717 469.789 438.165 485.375 441.274 505.102C447.98 547.723 457.166 606.09 430.547 705.674C419.385 744.407 416.689 778.354 422.53 806.595C428.006 833.066 441.029 854.742 461.192 870.988C482.278 887.976 511.069 899.053 546.765 903.911C580.954 908.566 620.792 907.449 665.171 900.592C752.509 887.102 853.077 851.759 948.36 801.075C1019.86 763.037 1063.59 727.103 1085.97 687.986C1106.33 652.412 1107.7 617.683 1109.29 577.468C1110.31 551.468 1111.37 524.586 1117.6 494.118L1117.62 494.021C1123.69 473.344 1122.17 456.631 1112.98 442.93C1085.1 401.375 990.55 393.176 870.844 382.793C860.787 381.921 850.56 381.036 840.195 380.113L840.192 380.117Z"
        fill="url(#paint5_linear_1868_89)"
      />
      <path
        d="M480.271 863.059C461.249 847.313 448.654 826.766 442.808 801.932C436.321 774.367 438.156 741.375 448.264 703.879C470.878 611.001 461.965 553.618 455.458 511.717C449.032 470.337 445.149 445.363 477.502 418.647C534.657 373.013 578.169 374.996 694.451 380.291C732.307 382.016 779.42 384.16 837.023 385.027L837.114 385.033C846.1 385.88 854.977 386.701 863.725 387.508C978.512 398.127 1069.18 406.511 1096.97 447.45C1106.3 461.186 1108.1 477.775 1102.48 498.167C1097.66 527.021 1097.29 552.774 1096.94 577.678C1095.82 656.482 1094.93 718.73 947.368 799.579C858.749 848.131 763.906 881.836 680.312 894.49C638.067 900.885 599.877 901.792 566.801 897.194C532.191 892.38 503.972 881.616 482.93 865.196C482.029 864.49 481.139 863.777 480.265 863.053L480.271 863.059ZM836.871 387.344C779.253 386.475 732.136 384.328 694.274 382.604C578.628 377.334 535.356 375.363 478.917 420.429C447.631 446.264 451.435 470.742 457.735 511.294C464.276 553.386 473.231 611.031 450.494 704.39C440.478 741.537 438.651 774.145 445.052 801.337C451.048 826.807 464.29 847.686 484.389 863.361C505.124 879.539 532.986 890.154 567.2 894.913C600.057 899.482 638.027 898.574 680.049 892.215C763.377 879.603 857.941 845.988 946.323 797.571C1013.65 760.687 1054.53 725.531 1074.99 686.936C1093.58 651.871 1094.07 617.513 1094.64 577.731C1094.99 552.72 1095.36 526.86 1100.23 497.807L1100.25 497.688C1105.7 477.964 1104 461.972 1095.05 448.804C1067.84 408.731 977.638 400.387 863.439 389.824C854.704 389.015 845.842 388.196 836.868 387.347L836.871 387.344Z"
        fill="url(#paint6_linear_1868_89)"
      />
      <path
        d="M502.321 857.484C484.224 842.505 471.927 823.205 465.732 800.039C458.633 773.496 459.571 741.803 468.516 705.851C487.342 619.242 478.64 562.738 472.286 521.478C468.96 499.882 466.333 482.828 468.43 467.505C470.702 450.916 478.38 437.927 493.336 425.397C547.79 381.536 589.449 383.8 700.794 389.856C736.266 391.784 780.409 394.186 834.035 395.479L834.121 395.486C841.724 396.248 849.244 396.994 856.666 397.729C965.916 408.538 1052.21 417.071 1079.35 456.517C1088.42 469.707 1090.42 485.565 1085.45 504.998C1081.94 532.376 1082.24 557.04 1082.53 580.894C1083.49 659.077 1084.25 720.825 945.677 799.278C863.925 845.557 775.122 877.538 695.629 889.326C655.764 895.238 619.465 895.954 587.736 891.46C554.643 886.769 527.371 876.5 506.685 860.939C505.196 859.817 503.736 858.667 502.315 857.491L502.321 857.484ZM833.871 397.785C780.229 396.49 736.08 394.089 700.604 392.158C589.914 386.137 548.494 383.885 494.771 427.162C464.076 452.891 468.024 478.546 474.569 521.054C480.95 562.494 489.69 619.244 470.767 706.31C461.911 741.91 460.968 773.208 467.966 799.376C474.514 823.866 488.033 843.956 508.116 859.064C528.503 874.4 555.424 884.525 588.14 889.16C619.649 893.624 655.728 892.907 695.374 887.029C774.584 875.281 863.097 843.403 944.602 797.263C1081.94 719.513 1081.19 658.377 1080.24 580.983C1079.94 557.029 1079.64 532.259 1083.18 504.692L1083.21 504.551C1088.03 485.771 1086.14 470.501 1077.45 457.862C1065.29 440.201 1039.89 427.701 997.496 418.522C958.905 410.166 909.107 405.24 856.381 400.023C848.969 399.29 841.464 398.545 833.874 397.782L833.871 397.785Z"
        fill="url(#paint7_linear_1868_89)"
      />
      <path
        d="M523.716 845.466C506.596 831.296 494.639 813.297 488.127 791.853C480.363 766.302 480.372 735.876 488.147 701.415C503.267 621.166 494.767 565.744 488.561 525.283C481.819 481.325 477.362 452.285 508.557 425.719C534.157 404.894 557.022 394.849 587.5 391.018C617.303 387.275 654.752 389.687 706.584 393.015C739.655 395.142 780.81 397.788 830.436 399.495L830.518 399.499C836.744 400.169 842.908 400.824 849.007 401.471C952.696 412.486 1034.59 421.19 1061.09 459.124C1069.92 471.774 1072.12 486.901 1067.79 505.382C1065.55 531.2 1066.49 554.689 1067.41 577.41C1068.99 616.759 1070.35 650.743 1055.12 685.414C1038.36 723.57 1002.85 757.61 943.368 792.537C868.457 836.521 785.753 866.77 710.49 877.709C673.034 883.155 638.65 883.701 608.292 879.336C576.734 874.8 550.432 865.061 530.103 850.391C527.903 848.804 525.774 847.158 523.722 845.46L523.716 845.466ZM830.24 401.805C780.598 400.096 739.437 397.451 706.363 395.321C600.588 388.52 561.01 385.979 509.994 427.475C479.839 453.152 483.982 480.142 490.841 524.857C497.073 565.484 505.608 621.128 490.406 701.81C482.713 735.914 482.686 765.946 490.336 791.111C497.487 814.651 511.336 833.961 531.486 848.505C570.835 876.901 634.314 886.46 710.229 875.424C785.201 864.529 867.603 834.386 942.252 790.552C1070.64 715.162 1068.2 654.442 1065.1 577.575C1064.19 554.758 1063.24 531.165 1065.5 505.169L1065.53 504.999C1069.72 487.158 1067.65 472.599 1059.19 460.485C1047.33 443.511 1023 431.383 982.606 422.326C945.864 414.088 898.666 409.075 848.697 403.768C842.611 403.12 836.456 402.467 830.246 401.798L830.24 401.805Z"
        fill="url(#paint8_linear_1868_89)"
      />
      <path
        d="M545.414 836.65C509.115 806.606 496.212 759.687 508.091 700.183C519.616 626.407 511.306 572.352 505.235 532.878C498.101 486.484 493.384 455.832 524.084 429.245C548.35 409.289 570.128 399.763 599.283 396.342C627.726 393.002 663.388 395.696 712.752 399.423C743.4 401.739 781.541 404.619 827.147 406.717L827.226 406.724C832.082 407.286 836.899 407.834 841.675 408.382C888.939 413.794 933.581 418.907 968.6 427.061C1007.55 436.132 1031.24 448.164 1043.13 464.926C1051.74 477.036 1054.13 491.449 1050.45 508.977C1049.42 533.127 1050.96 555.333 1052.46 576.81C1055.07 614.258 1057.54 649.629 1044.16 683.925C1029.41 721.73 996.754 755.126 941.379 789.011C873.295 830.665 796.759 859.179 725.865 869.299C654.406 879.5 593.438 870.255 554.195 843.265C551.135 841.158 548.209 838.952 545.423 836.646L545.414 836.65ZM826.924 409.033C781.305 406.931 743.154 404.048 712.5 401.733C611.603 394.116 573.847 391.263 525.531 430.996C495.859 456.697 500.495 486.839 507.51 532.458C513.601 572.077 521.95 626.338 510.359 700.521C503.839 733.194 504.775 762.006 513.14 786.209C520.958 808.829 535.217 827.382 555.529 841.35C594.309 868.02 654.707 877.138 725.602 867.018C796.186 856.944 872.404 828.546 940.215 787.056C1059.69 713.946 1055.48 653.537 1050.15 577.055C1048.65 555.487 1047.09 533.185 1048.14 508.867L1048.16 508.676C1051.73 491.766 1049.46 477.911 1041.23 466.316C1029.66 450.021 1006.39 438.264 967.999 429.327C933.108 421.203 888.53 416.096 841.329 410.69C836.565 410.146 831.764 409.594 826.917 409.034L826.924 409.033Z"
        fill="url(#paint9_linear_1868_89)"
      />
      <path
        d="M566.976 827.738C532.015 798.802 518.567 754.614 528.033 698.968C536.103 631.827 528.301 581.613 522.034 541.271C514.382 492.027 509.328 459.494 539.607 432.781C562.544 413.69 583.246 404.684 611.091 401.679C638.183 398.755 672.08 401.749 718.995 405.891C748.664 408.512 782.292 411.482 823.85 413.955L834.356 415.257C878.83 420.773 920.841 425.981 953.993 434.033C990.911 442.997 1013.52 454.653 1025.14 470.716C1033.51 482.294 1036.12 495.988 1033.09 512.584C1033.19 534.926 1035.29 555.698 1037.31 575.79C1041.08 613.057 1044.63 648.262 1033.15 682.255C1020.47 719.797 990.677 752.605 939.382 785.496C878.116 824.777 807.836 851.547 741.493 860.882C675.208 870.206 617.421 861.508 578.779 836.388C574.608 833.676 570.674 830.787 566.982 827.731L566.976 827.738ZM1013.49 461.818C1000.79 451.307 981.241 443.046 953.385 436.281C920.357 428.263 878.41 423.06 834.002 417.553L823.569 416.259C782.049 413.788 748.405 410.817 718.723 408.197C671.938 404.063 638.136 401.078 611.265 403.98C583.924 406.931 563.59 415.78 541.072 434.519C511.804 460.336 516.781 492.363 524.315 540.844C530.606 581.328 538.434 631.715 530.317 699.231C520.99 754.072 534.189 797.551 568.519 825.966C572.138 828.961 575.985 831.784 580.07 834.442C618.26 859.269 675.501 867.848 741.242 858.598C807.259 849.308 877.199 822.659 938.181 783.563C988.992 750.982 1018.48 718.576 1030.97 681.581C1042.29 648.067 1038.92 614.717 1035.02 576.096C1032.98 555.917 1030.87 535.055 1030.78 512.558L1030.8 512.343C1033.75 496.352 1031.28 483.19 1023.26 472.113C1020.62 468.46 1017.38 465.035 1013.49 461.821L1013.49 461.818Z"
        fill="url(#paint10_linear_1868_89)"
      />
      <path
        d="M588.426 818.751C554.863 790.971 540.915 749.543 547.98 697.751C552.781 637.465 545.153 589.364 539.025 550.717C534.977 525.175 531.479 503.115 532.752 484.404C534.137 464.076 541.248 448.791 555.129 436.304C598.89 399.376 635.907 403.192 725.341 412.413C752.401 415.203 783.073 418.363 820.559 421.179L827.055 422.053C938.152 437.055 1025.92 448.91 1015.73 516.172C1016.84 536.509 1019.42 555.651 1021.91 574.164C1026.91 611.369 1031.63 646.514 1022.11 680.308C1011.57 717.704 984.648 750.007 937.393 781.978C882.941 818.81 819.038 843.837 757.46 852.441C696.52 860.959 642.038 852.929 604.043 829.833C598.442 826.428 593.229 822.726 588.426 818.751ZM995.741 468.035C988.147 461.75 978.171 456.291 965.72 451.545C931.527 438.507 882.937 431.947 826.676 424.346L820.249 423.477C782.801 420.663 752.113 417.501 725.037 414.71C680.673 410.138 648.62 406.833 623.086 409.308C597.054 411.83 577.795 420.153 556.613 438.027C527.64 464.093 533.077 498.378 541.306 550.283C547.453 589.052 555.102 637.304 550.274 697.929C543.315 748.94 557.017 789.698 589.979 816.98C594.692 820.881 599.791 824.501 605.287 827.842C642.841 850.672 696.796 858.592 757.218 850.146C818.444 841.59 881.99 816.7 936.147 780.066C982.903 748.435 1009.52 716.554 1019.89 679.738C1029.29 646.414 1024.59 611.502 1019.62 574.541C1017.12 555.95 1014.53 536.725 1013.42 516.25L1013.43 516.01C1016.47 496.184 1010.79 480.486 995.744 468.032L995.741 468.035Z"
        fill="url(#paint11_linear_1868_89)"
      />
      <path
        d="M610.04 809.881C597.784 799.737 588.027 787.736 580.895 774.01C569.43 751.944 565.071 725.883 567.934 696.553C569.715 643.455 562.286 598.123 556.316 561.698C547.333 506.86 540.838 467.241 570.657 439.84C611.791 404.588 646.928 408.814 731.818 419.03C756.229 421.967 783.9 425.297 817.274 428.414L819.788 428.785C923.678 444.162 1005.75 456.301 998.374 519.77C1000.34 537.839 1003.27 555.058 1006.11 571.712C1012.48 609.018 1018.49 644.257 1011 677.991C1002.69 715.401 978.671 747.327 935.417 778.461C887.773 812.752 830.409 836.017 773.887 843.971C718.514 851.762 667.516 844.56 630.284 823.69C622.923 819.564 616.17 814.955 610.04 809.881ZM978.045 474.196C949.144 450.276 889.254 441.414 819.376 431.069L816.923 430.707C783.58 427.594 755.894 424.262 731.466 421.323C647.297 411.196 612.457 407.007 572.146 441.551C543.312 468.047 549.717 507.141 558.585 561.254C564.569 597.778 572.016 643.236 570.224 696.634C567.401 725.589 571.68 751.222 582.938 772.89C593.43 793.089 609.753 809.498 631.45 821.657C668.256 842.29 718.75 849.397 773.628 841.674C829.778 833.773 886.767 810.659 934.102 776.59C976.843 745.819 1000.56 714.347 1008.73 677.549C1016.13 644.265 1010.16 609.242 1003.83 572.161C1000.98 555.433 998.029 538.134 996.057 519.953L996.059 519.692C998.376 500.043 991.639 485.448 978.038 474.191L978.045 474.196Z"
        fill="url(#paint12_linear_1868_89)"
      />
      <path
        d="M631.576 800.94C600.942 775.586 585.605 739.056 587.882 695.404C587.003 649.939 580.145 610.104 574.086 574.958C563.741 514.889 556.262 471.489 586.176 443.364C624.708 409.751 658.009 414.439 738.46 425.754C760.166 428.811 784.769 432.269 813.987 435.638C860.199 443.187 903.799 450.314 934.476 462.912C968.277 476.796 983.069 496 981.01 523.349C983.572 538.744 986.697 553.625 989.721 568.014C997.631 605.661 1005.1 641.223 999.759 675.109C993.819 712.777 972.747 744.498 933.443 774.94C892.624 806.549 842 828.037 790.909 835.443C741.412 842.615 694.181 836.446 657.912 818.063C648.168 813.126 639.372 807.392 631.572 800.937L631.576 800.94ZM960.324 480.35C953.404 474.622 944.505 469.562 933.544 465.059C903.105 452.557 859.629 445.452 813.598 437.93C784.405 434.565 759.789 431.101 738.071 428.046C658.366 416.835 625.374 412.192 587.695 445.059C558.754 472.268 566.139 515.147 576.36 574.494C582.432 609.733 589.311 649.673 590.189 695.329L590.189 695.411C588.726 723.352 594.405 748.177 607.077 769.192C618.877 788.761 636.344 804.507 659.006 815.993C694.866 834.166 741.62 840.261 790.654 833.155C841.348 825.805 891.573 804.489 932.072 773.128C970.86 743.088 991.642 711.851 997.48 674.822C1002.76 641.361 995.325 606 987.463 568.561C984.427 554.104 981.284 539.156 978.709 523.662L978.697 523.384C980.05 505.855 974.134 491.78 960.321 480.347L960.324 480.35Z"
        fill="url(#paint13_linear_1868_89)"
      />
      <path
        d="M652.77 791.802C644.058 784.592 636.502 776.47 630.142 767.471C615.384 746.588 607.885 721.944 607.852 694.232C604.889 657.331 598.443 622.59 592.761 591.937C580.768 527.273 571.299 476.202 601.713 446.887C637.683 414.863 669.196 420.071 745.341 432.65C764.265 435.774 785.714 439.319 810.718 442.865C857.533 451.4 892.706 458.342 919.432 470.054C950.293 483.578 964.352 501.651 963.654 526.925C966.421 539.027 969.437 550.897 972.355 562.376C991.948 639.453 1008.87 706.021 931.5 771.423C897.014 800.567 854.565 819.723 808.749 826.823C765.529 833.522 722.497 828.644 687.579 813.094C674.492 807.264 662.864 800.144 652.776 791.795L652.77 791.802ZM942.541 486.425C936.186 481.165 928.179 476.447 918.443 472.183C891.941 460.568 856.893 453.654 810.271 445.151C785.287 441.61 763.822 438.063 744.885 434.934C669.52 422.481 638.323 417.33 603.238 448.563C573.783 476.951 583.157 527.475 595.021 591.443C600.717 622.161 607.176 656.982 610.149 694.021L610.153 694.111C610.176 721.365 617.541 745.584 632.036 766.096C645.521 785.178 664.54 800.283 688.561 810.982C723.085 826.358 765.669 831.175 808.457 824.545C853.845 817.51 895.887 798.536 930.036 769.68C964.955 740.165 982.745 708.883 986.021 671.238C988.978 637.258 979.813 601.2 970.11 563.022C967.179 551.485 964.145 539.554 961.362 527.375L961.333 527.082C961.823 510.66 955.762 497.373 942.538 486.428L942.541 486.425Z"
        fill="url(#paint14_linear_1868_89)"
      />
      <path
        d="M673.777 782.415C667.406 777.142 661.613 771.42 656.482 765.299C639.25 744.743 629.604 720.445 627.824 693.077C623.778 666.419 618.447 640.66 613.287 615.749C605.862 579.9 598.853 546.038 597.728 517.815C596.471 486.255 602.671 464.836 617.242 450.41C633.504 435.579 649.574 429.027 671.017 428.468C691.744 427.925 717.239 432.903 752.525 439.796C768.563 442.928 786.738 446.478 807.449 450.096C845.823 458.009 879.58 465.505 904.381 477.202C932.292 490.37 945.614 507.311 946.283 530.502C948.573 538.236 950.924 545.945 953.202 553.401C977.671 633.543 998.806 702.756 929.562 767.902C901.985 793.84 866.722 811.174 827.589 818.037C791.156 824.424 752.999 821.169 720.143 808.866C702.698 802.334 687.001 793.348 673.789 782.414L673.777 782.415ZM924.654 492.41C918.894 487.643 911.812 483.3 903.335 479.303C878.759 467.71 845.14 460.246 806.942 452.374C786.241 448.759 768.054 445.209 752.008 442.072C680.801 428.164 651.329 422.403 618.797 452.071C588.063 482.502 600.8 544.006 615.548 615.214C620.714 640.171 626.059 665.977 630.113 692.712L630.124 692.808C631.867 719.703 641.334 743.583 658.271 763.778C673.809 782.308 695.5 797.148 721.001 806.697C753.485 818.862 791.221 822.078 827.254 815.762C865.933 808.98 900.77 791.859 928.007 766.241C996.169 702.115 975.231 633.544 950.989 554.144C948.697 546.637 946.327 538.876 944.022 531.084L943.977 530.941L943.972 530.79C943.567 515.437 937.332 502.903 924.657 492.413L924.654 492.41Z"
        fill="url(#paint15_linear_1868_89)"
      />
      <path
        d="M694.649 773.031C691.189 770.167 687.878 767.17 684.734 764.048C664.447 743.906 651.678 718.974 647.814 691.942C644.926 679.721 641.847 667.265 638.868 655.214C617.805 569.98 597.911 489.476 632.764 453.924C663.773 424.836 693.974 431.886 760.146 447.326C773.139 450.356 787.861 453.79 804.177 457.322C872.204 473.456 924.576 489.351 928.892 534.064L930.308 537.905C945.91 580.23 960.649 620.21 963.805 657.865C967.349 700.146 955.852 733.992 927.632 764.373C906.857 786.732 879.146 802.135 847.492 808.919C817.987 815.244 786.531 813.96 756.523 805.219C733.122 798.399 711.976 787.371 694.649 773.031ZM906.547 498.257C884.829 480.283 847.517 469.994 803.602 459.58C787.293 456.054 772.557 452.615 759.558 449.579C726.503 441.867 702.622 436.291 683.275 436.215C663.517 436.137 648.884 441.919 634.349 455.551C600.467 490.114 620.207 570.006 641.11 654.587C644.093 666.66 647.178 679.143 650.07 691.386L650.088 691.488C653.878 718.049 666.426 742.565 686.379 762.372C705.008 780.868 729.506 794.91 757.226 802.991C786.874 811.633 817.942 812.899 847.076 806.652C878.246 799.971 905.527 784.813 925.962 762.821C953.691 732.969 964.984 699.702 961.5 658.125C958.37 620.772 943.687 580.937 928.133 538.758L926.618 534.642L926.604 534.495C925.189 519.475 918.058 507.783 906.54 498.251L906.547 498.257Z"
        fill="url(#paint16_linear_1868_89)"
      />
      <path
        d="M714.945 763.197C690.654 743.094 674.364 718.081 667.819 690.829C649.322 636.135 637.913 592.147 632.939 556.347C626.285 508.462 631.45 475.186 648.292 457.442C676.973 429.606 705.654 437.727 768.494 455.52C778.189 458.266 789.177 461.375 800.922 464.555C857.064 480.224 904.134 496.532 911.496 537.628C928.926 576.588 945.771 617.916 951.027 656.269C956.681 697.499 948.405 731.707 925.73 760.848C911.205 779.505 891.754 792.431 867.916 799.272C846.221 805.495 821.587 806.248 796.677 801.441C767.015 795.718 738.153 782.269 715.403 763.576C715.251 763.45 715.1 763.325 714.949 763.2L714.945 763.197ZM888.093 503.631C867.954 486.963 835.967 476.756 800.248 466.791C788.499 463.607 777.504 460.493 767.803 457.748C705.849 440.208 677.572 432.201 649.911 459.044C621.141 489.36 627.709 564.946 670.027 690.073L670.053 690.171C676.478 716.967 692.515 741.583 716.449 761.391C716.601 761.516 716.749 761.639 716.896 761.761C739.365 780.221 767.877 793.503 797.178 799.161C821.737 803.899 845.998 803.166 867.342 797.043C890.681 790.345 909.719 777.696 923.92 759.455C946.177 730.851 954.296 697.226 948.731 656.653C943.509 618.545 926.703 577.349 909.32 538.503L909.239 538.238C906.726 524.031 899.218 512.843 888.087 503.631L888.093 503.631Z"
        fill="url(#paint17_linear_1868_89)"
      />
      <path
        d="M734.544 752.855C712.545 734.649 696.215 712.678 687.855 689.741C668.882 648.788 654.746 601.625 649.074 560.345C640.844 500.493 652.555 473.253 663.827 460.938C690.378 434.074 719.69 444.385 778.084 464.928C784.332 467.128 790.793 469.399 797.684 471.78C840.652 486.34 883.49 503.687 894.085 541.17C917.872 585.757 932.371 623.459 938.405 656.436C945.683 696.196 940.922 729.181 923.851 757.282C906.902 785.163 876.14 798.934 837.231 796.055C807.365 793.847 775.672 782.11 747.99 763.012C743.306 759.78 738.819 756.387 734.547 752.852L734.544 752.855ZM869.061 508.658C850.406 493.219 823.673 483.06 796.878 473.984C789.973 471.598 783.512 469.327 777.261 467.124C747.716 456.731 726.37 449.218 708.817 447.44C690.698 445.603 677.733 450.113 665.475 462.513C650.064 479.35 645.044 513.968 651.365 559.961C657.012 601.039 671.085 647.985 689.977 688.755L690.012 688.842C699.83 715.816 720.9 741.476 749.335 761.096C776.695 779.974 807.999 791.57 837.469 793.749C875.451 796.562 905.431 783.197 921.885 756.129C938.64 728.55 943.301 696.102 936.131 656.928C930.132 624.161 915.691 586.637 891.98 542.211L891.89 541.985C888.11 528.511 879.966 517.684 869.061 508.658Z"
        fill="url(#paint18_linear_1868_89)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1868_89"
          x1="759.511"
          y1="740.947"
          x2="298.204"
          y2="415.687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1868_89"
          x1="762.153"
          y1="736.49"
          x2="314.413"
          y2="421.312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1868_89"
          x1="764.822"
          y1="732.058"
          x2="330.596"
          y2="426.942"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1868_89"
          x1="767.545"
          y1="727.68"
          x2="346.793"
          y2="432.615"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1868_89"
          x1="770.315"
          y1="723.334"
          x2="362.954"
          y2="438.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1868_89"
          x1="773.103"
          y1="719.013"
          x2="379.061"
          y2="443.953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1868_89"
          x1="775.996"
          y1="714.769"
          x2="395.126"
          y2="449.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1868_89"
          x1="779.267"
          y1="713.81"
          x2="411.476"
          y2="458.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1868_89"
          x1="781.958"
          y1="706.442"
          x2="427.217"
          y2="461.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1868_89"
          x1="785.06"
          y1="702.38"
          x2="443.163"
          y2="466.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1868_89"
          x1="788.26"
          y1="698.414"
          x2="459.057"
          y2="472.676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1868_89"
          x1="791.583"
          y1="694.559"
          x2="474.901"
          y2="478.555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1868_89"
          x1="795.079"
          y1="690.793"
          x2="490.691"
          y2="484.459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1868_89"
          x1="798.711"
          y1="687.199"
          x2="506.344"
          y2="490.482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1868_89"
          x1="802.558"
          y1="683.789"
          x2="521.923"
          y2="496.633"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1868_89"
          x1="806.73"
          y1="680.597"
          x2="537.358"
          y2="502.916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1868_89"
          x1="811.223"
          y1="677.749"
          x2="552.579"
          y2="509.472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1868_89"
          x1="816.251"
          y1="675.284"
          x2="567.599"
          y2="516.326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1868_89"
          x1="821.737"
          y1="673.294"
          x2="582.273"
          y2="523.721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
