import { SvgIcon } from "@mui/material";
export const HeaderLinesIcon = ({ ...props }) => {
  return (
    <SvgIcon
      width="373"
      height="699"
      viewBox="0 0 373 699"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.2">
        <path
          d="M595.253 154.006C596.073 154.575 596.874 155.156 597.661 155.755C625.26 176.778 631.833 215.337 617.191 270.366C596.763 347.15 606.093 382.509 612.267 405.906C617.345 425.15 620.143 435.749 600.527 453.648L600.091 453.16L600.527 453.648C580.942 471.522 563.382 481.247 539.921 487.216C516.686 493.128 488.254 495.324 445.219 498.646C415.33 500.954 378.132 503.827 331.515 508.888L331.454 508.893C321.371 509.053 311.427 509.251 301.81 509.441C216.423 511.138 148.975 512.481 128.141 485.343C121.025 476.077 119.585 464.014 123.741 448.471C129.548 426.751 130.773 407.743 131.852 390.97C133.468 365.858 134.863 344.17 149.86 320.343C166.195 294.386 197.698 268.337 249.004 238.356C284.64 217.534 320.255 199.306 354.854 184.185C390.171 168.748 423.554 156.934 454.076 149.071C518.134 132.569 566.84 134.291 595.253 153.997L595.253 154.006ZM331.441 507.56C378.064 502.5 415.265 499.626 445.156 497.316C488.122 493.997 516.507 491.806 539.631 485.921C562.857 480.013 580.247 470.378 599.652 452.67C618.649 435.337 616.088 425.627 610.985 406.287C607.893 394.566 604.044 379.982 603.474 358.427C602.799 332.961 606.866 304.055 615.912 270.055C630.4 215.598 623.985 177.504 596.842 156.829C596.071 156.241 595.279 155.666 594.474 155.108C566.382 135.618 518.051 133.968 454.367 150.373C423.913 158.217 390.6 170.007 355.349 185.414C320.798 200.515 285.234 218.718 249.641 239.514C198.535 269.374 167.182 295.279 150.972 321.038C136.165 344.568 134.777 366.095 133.175 391.021C132.091 407.854 130.864 426.936 125.022 448.786C120.98 463.901 122.348 475.585 129.199 484.507C149.645 511.134 216.802 509.796 301.826 508.106C311.434 507.916 321.369 507.718 331.446 507.557L331.441 507.56Z"
          fill="url(#paint0_linear_1840_2312)"
        />
        <path
          d="M583.106 160.077C583.754 160.526 584.39 160.983 585.015 161.452C598.172 171.278 606.697 185.264 610.353 203.016C614.216 221.774 612.68 245.006 605.785 272.062C587.415 345.172 596.558 380.426 602.612 403.752C607.784 423.687 610.632 434.668 591.423 452.373C572.578 469.659 555.637 479.031 532.952 484.723C510.512 490.353 483.082 492.335 441.559 495.337C412.997 497.401 377.45 499.971 333.057 504.567L332.997 504.57C323.733 504.687 314.582 504.836 305.733 504.981C223.468 506.326 158.493 507.39 138.14 481.076C131.209 472.114 129.704 460.494 133.538 445.543C138.582 424.587 139.364 406.91 140.123 389.813C141.219 365.067 142.166 343.696 156.195 320.299C171.494 294.784 201.407 269.361 250.337 240.29C318.358 199.872 387.9 169.439 446.146 154.592C507.966 138.834 555.235 140.744 583.103 160.072L583.106 160.077ZM332.994 503.237C377.391 498.638 412.943 496.071 441.508 494.006C482.959 491.011 510.345 489.03 532.671 483.428C555.118 477.797 571.886 468.518 590.547 451.4C599.376 443.261 603.783 436.272 604.838 428.733C605.847 421.533 603.852 413.851 601.328 404.126C595.242 380.667 586.047 345.216 604.5 271.767C617.863 219.32 611.034 182.57 584.203 162.531C583.589 162.073 582.963 161.62 582.328 161.179C554.776 142.068 507.881 140.228 446.435 155.889C388.309 170.704 318.896 201.084 250.983 241.438C202.259 270.386 172.498 295.66 157.323 320.967C143.473 344.064 142.533 365.275 141.445 389.835C140.721 406.197 139.898 424.741 134.824 445.824L134.818 445.832C131.092 460.36 132.523 471.613 139.192 480.236C159.151 506.043 223.844 504.985 305.748 503.648C314.591 503.503 323.731 503.353 332.988 503.236L332.994 503.237Z"
          fill="url(#paint1_linear_1840_2312)"
        />
        <path
          d="M570.971 166.157C571.429 166.474 571.878 166.796 572.327 167.122C585.332 176.629 593.885 190.13 597.745 207.249C601.826 225.35 600.694 247.729 594.38 273.761C578.048 343.186 587.011 378.289 592.943 401.52C598.221 422.192 601.13 433.578 582.321 451.103C545.779 484.809 516.403 486.774 437.892 492.024C410.66 493.846 376.766 496.112 334.605 500.248L334.547 500.252C326.1 500.328 317.742 500.432 309.66 500.534C230.524 501.525 168.02 502.305 148.15 476.817C141.401 468.161 139.83 456.973 143.346 442.613C147.63 422.443 148.029 405.29 148.418 388.705C148.987 364.318 149.479 343.255 162.537 320.281C176.792 295.199 205.116 270.398 251.674 242.224C315.774 203.435 382.015 174.278 438.193 160.119C497.784 145.101 543.634 147.198 570.969 166.155L570.971 166.157ZM334.545 498.917C376.713 494.78 410.611 492.513 437.844 490.692C477.787 488.022 504.174 486.257 525.706 480.939C547.375 475.586 563.523 466.663 581.443 450.135C599.649 433.169 596.811 422.058 591.661 401.892C585.697 378.537 576.683 343.242 593.094 273.489C599.36 247.658 600.488 225.488 596.454 207.586C592.665 190.782 584.278 177.534 571.53 168.216C544.67 148.6 498.68 146.247 438.482 161.419C382.431 175.546 316.322 204.651 252.335 243.369C205.988 271.416 177.819 296.057 163.686 320.923C150.799 343.597 150.311 364.499 149.746 388.702C149.358 405.352 148.956 422.569 144.643 442.87L144.637 442.891C141.222 456.829 142.714 467.653 149.205 475.976C168.685 500.969 230.91 500.187 309.688 499.205C317.761 499.106 326.11 498.998 334.55 498.923L334.545 498.917Z"
          fill="url(#paint2_linear_1840_2312)"
        />
        <path
          d="M558.847 172.244C559.095 172.416 559.342 172.588 559.587 172.763C572.456 181.946 581.046 194.966 585.12 211.456C589.43 228.906 588.709 250.44 582.976 275.455C568.659 341.185 577.444 376.091 583.258 399.188C585.908 409.721 588.003 418.041 587.134 425.894C586.208 434.252 581.916 441.635 573.221 449.831C538.158 482.365 509.853 484.091 434.203 488.705C408.304 490.284 376.076 492.248 336.148 495.928L336.092 495.93C328.456 495.971 320.891 496.033 313.575 496.094C237.576 496.72 177.547 497.217 158.16 472.555C151.594 464.201 149.954 453.448 153.147 439.682C156.682 420.308 156.706 403.704 156.728 387.644C156.763 363.603 156.793 342.842 168.875 320.282C182.08 295.626 208.813 271.435 253.007 244.151C313.204 206.992 376.135 179.11 430.203 165.642C487.579 151.353 532.029 153.638 558.847 172.241L558.847 172.244ZM336.099 494.595C376.032 490.916 408.264 488.951 434.165 487.373C472.6 485.029 497.995 483.479 518.736 478.446C539.63 473.374 555.159 464.803 572.337 448.865C590.157 432.067 587.25 420.52 581.973 399.555C576.129 376.336 567.296 341.248 581.684 275.202C587.368 250.387 588.093 229.063 583.833 211.817C579.836 195.633 571.414 182.865 558.801 173.864C558.559 173.693 558.317 173.522 558.074 173.353C531.569 154.968 487.486 152.749 430.487 166.946C376.55 180.378 313.758 208.202 253.678 245.291C209.701 272.439 183.125 296.462 170.041 320.893C158.121 343.149 158.091 363.753 158.058 387.608C158.035 403.73 158.009 420.403 154.451 439.902L154.443 439.935C151.342 453.289 152.9 463.682 159.208 471.706C178.214 495.879 237.962 495.383 313.608 494.757C320.915 494.696 328.474 494.634 336.1 494.593L336.099 494.595Z"
          fill="url(#paint3_linear_1840_2312)"
        />
        <path
          d="M546.741 178.34L546.782 178.369C559.534 187.226 568.177 199.764 572.472 215.633C577.023 232.438 576.718 253.135 571.57 277.148C559.24 339.166 567.85 373.814 573.548 396.741C578.912 418.326 582.151 431.356 564.12 448.56C530.535 479.927 503.298 481.411 430.497 485.381C405.939 486.719 375.379 488.387 337.693 491.607L337.637 491.609C330.814 491.615 324.09 491.639 317.489 491.664C244.636 491.928 187.09 492.139 168.17 468.298C161.783 460.248 160.073 449.932 162.948 436.753C165.742 418.201 165.396 402.161 165.063 386.648C164.553 362.943 164.114 342.469 175.212 320.315C187.357 296.072 212.498 272.484 254.337 246.082C310.65 210.55 370.255 183.948 422.169 171.179C477.341 157.607 520.416 160.083 546.735 178.339L546.741 178.34ZM337.651 490.272C375.343 487.05 405.908 485.383 430.467 484.046C502.911 480.096 530.016 478.617 563.229 447.598C580.673 430.955 577.686 418.933 572.263 397.104C566.535 374.062 557.884 339.24 570.275 276.916C575.381 253.103 575.69 232.62 571.195 216.02C566.98 200.453 558.506 188.159 546.008 179.478L545.967 179.449C532.766 170.292 515.127 165.135 493.531 164.117C472.877 163.143 448.963 165.956 422.451 172.477C370.672 185.215 311.211 211.756 255.019 247.211C213.404 273.471 188.421 296.882 176.394 320.889C165.448 342.739 165.886 363.054 166.391 386.579C166.727 402.154 167.073 418.26 164.256 436.936L164.247 436.98C161.458 449.751 163.082 459.715 169.214 467.443C187.745 490.796 245.016 490.589 317.523 490.326C324.116 490.302 330.832 490.278 337.649 490.271L337.651 490.272Z"
          fill="url(#paint4_linear_1840_2312)"
        />
        <path
          d="M534.638 184.439C546.891 192.938 555.355 204.819 559.799 219.771C564.604 235.937 564.728 255.811 560.164 278.839C549.79 337.124 557.893 370.087 563.81 394.155C569.338 416.644 572.675 430.219 555.019 447.285C522.91 477.491 496.731 478.731 426.77 482.047C403.56 483.145 374.672 484.516 339.242 487.285L339.189 487.287C333.173 487.264 327.242 487.255 321.406 487.246C251.704 487.141 196.646 487.055 178.195 464.047C171.983 456.301 170.205 446.415 172.759 433.826C174.827 416.12 174.125 400.668 173.443 385.728C171.352 339.841 169.699 303.595 255.678 248.015C308.121 214.114 364.384 188.793 414.096 176.72C439.412 170.574 462.398 167.967 482.416 168.974C503.422 170.029 520.745 175.064 533.9 183.934C534.147 184.102 534.397 184.271 534.642 184.442L534.638 184.439ZM339.203 485.95C374.644 483.178 403.532 481.811 426.746 480.712C496.346 477.414 522.389 476.18 554.122 446.329C562.181 438.54 566.09 431.236 566.787 422.66C567.446 414.556 565.274 405.712 562.519 394.518C556.573 370.332 548.43 337.212 558.861 278.632C563.383 255.808 563.27 236.151 558.527 220.194C554.08 205.237 545.528 193.409 533.135 185.052C520.175 176.315 503.073 171.355 482.304 170.309C462.412 169.307 439.554 171.902 414.365 178.02C364.793 190.058 308.683 215.314 256.363 249.134C217.104 274.515 193.714 297.325 182.757 320.92C172.791 342.378 173.704 362.42 174.762 385.627C175.446 400.632 176.154 416.145 174.067 433.974L174.058 434.031C171.58 446.221 173.274 455.758 179.231 463.189C197.303 485.723 252.084 485.806 321.442 485.913C327.269 485.921 333.195 485.929 339.202 485.952L339.203 485.95Z"
          fill="url(#paint5_linear_1840_2312)"
        />
        <path
          d="M522.55 190.548C534.262 198.672 542.513 209.873 547.093 223.869C552.174 239.406 552.735 258.471 548.759 280.532C540.303 335.06 548.241 367.627 554.037 391.408C559.76 414.893 563.217 429.067 545.918 446.018C515.281 475.065 490.158 476.056 423.019 478.709C401.161 479.572 373.958 480.647 340.785 482.971L340.733 482.972C335.523 482.925 330.377 482.887 325.306 482.852C258.765 482.369 206.205 481.989 188.216 459.805C182.177 452.362 180.329 442.908 182.56 430.905C183.922 414.072 182.87 399.243 181.854 384.902C178.637 339.522 176.095 303.677 257.007 249.945C305.598 217.678 358.496 193.646 405.955 182.272C429.94 176.523 451.86 174.131 471.109 175.155C491.252 176.227 508.009 181.036 520.916 189.449C521.469 189.811 522.016 190.177 522.554 190.551L522.55 190.548ZM340.759 481.631C373.941 479.307 401.146 478.233 423.007 477.37C489.779 474.734 514.764 473.748 545.017 445.062C561.745 428.67 558.358 414.778 552.748 391.763C546.923 367.874 538.948 335.158 547.451 280.348C551.392 258.492 550.845 239.648 545.831 224.322C541.134 209.966 532.495 198.606 520.167 190.576C507.448 182.287 490.903 177.547 470.992 176.486C451.87 175.468 430.076 177.851 406.218 183.567C358.91 194.904 306.168 218.871 257.707 251.049C220.793 275.562 199 297.785 189.123 320.986C180.149 342.065 181.552 361.85 183.176 384.759C184.198 399.161 185.253 414.052 183.877 431.001L183.868 431.071C181.703 442.682 183.463 451.796 189.253 458.932C206.866 480.647 259.157 481.026 325.357 481.506C330.421 481.543 335.558 481.58 340.76 481.629L340.759 481.631Z"
          fill="url(#paint6_linear_1840_2312)"
        />
        <path
          d="M510.141 194.835C521.284 202.563 529.302 213.061 534 226.081C539.384 241 540.397 259.274 537.014 280.39C530.43 331.127 538.203 363.199 543.88 386.619C546.851 398.876 549.197 408.556 548.742 417.473C548.248 427.125 544.468 434.972 536.48 442.911C507.31 470.806 483.239 471.546 418.902 473.518C398.405 474.147 372.898 474.929 341.991 476.812L341.942 476.813C337.532 476.747 333.17 476.686 328.865 476.627C265.5 475.764 215.451 475.084 197.909 453.726C192.042 446.585 190.119 437.561 192.025 426.141C192.702 410.222 191.321 396.052 189.982 382.346C185.598 337.426 182.137 301.948 257.995 250.036C302.747 219.412 352.256 196.667 397.399 185.992C420.038 180.639 440.88 178.448 459.349 179.478C478.613 180.555 494.801 185.125 507.462 193.061C508.373 193.633 509.269 194.223 510.144 194.83L510.141 194.835ZM341.973 475.478C372.889 473.595 398.399 472.812 418.898 472.185C482.857 470.224 506.79 469.49 535.569 441.967C551.962 425.664 548.434 411.102 542.587 386.974C536.887 363.452 529.079 331.24 535.697 280.237C539.046 259.327 538.055 241.28 532.748 226.572C527.782 212.808 519.022 201.915 506.731 194.21C494.254 186.388 478.274 181.884 459.23 180.821C440.888 179.797 420.172 181.978 397.658 187.301C352.676 197.939 303.329 220.611 258.711 251.142C183.53 302.59 186.958 337.716 191.297 382.182C192.641 395.946 194.029 410.177 193.342 426.206L193.334 426.288C191.481 437.326 193.315 446.016 198.934 452.86C206.79 462.422 222.013 468.367 246.847 471.569C269.452 474.484 298.335 474.877 328.916 475.294C333.216 475.352 337.568 475.413 341.972 475.48L341.973 475.478Z"
          fill="url(#paint7_linear_1840_2312)"
        />
        <path
          d="M498.424 202.795C508.965 210.106 516.724 219.873 521.521 231.887C527.238 246.202 528.724 263.703 525.941 283.904C521.176 330.8 528.781 362.26 534.332 385.227C540.364 410.18 544.35 426.664 527.71 443.472C514.006 456.704 501.348 463.602 484.006 467.299C467.048 470.911 445.391 471.36 415.416 471.985C396.291 472.382 372.491 472.876 343.865 474.326L343.817 474.328C340.204 474.248 336.626 474.173 333.087 474.1C272.91 472.845 225.379 471.852 208.28 451.332C202.58 444.489 200.579 435.896 202.162 425.055C202.184 410.096 200.492 396.632 198.853 383.609C196.015 361.054 193.564 341.576 200.626 320.888C208.397 298.121 227.153 276.803 259.653 253.8C300.583 224.831 346.668 203.382 389.421 193.402C410.697 188.434 430.446 186.436 448.121 187.459C466.494 188.521 482.099 192.834 494.51 200.275C495.853 201.081 497.158 201.923 498.421 202.799L498.424 202.795ZM343.864 472.988C372.5 471.539 396.303 471.043 415.43 470.648C476.601 469.377 499.489 468.899 526.797 442.533C542.883 426.287 539.178 410.966 533.042 385.584C527.467 362.523 519.831 330.937 524.622 283.788C527.375 263.796 525.919 246.521 520.286 232.422C515.02 219.233 506.108 208.806 493.806 201.428C469.784 187.024 432.805 184.636 389.682 194.703C347.096 204.643 301.179 226.018 260.392 254.887C190.244 304.539 194.624 339.342 200.168 383.401C201.814 396.48 203.514 410.003 203.488 425.066L203.48 425.164C201.943 435.631 203.846 443.904 209.305 450.457C216.957 459.638 231.549 465.421 255.225 468.651C276.761 471.589 304.152 472.159 333.153 472.764C336.685 472.838 340.257 472.912 343.861 472.993L343.864 472.988Z"
          fill="url(#paint8_linear_1840_2312)"
        />
        <path
          d="M486.377 208.929C508.726 224.43 518.447 250.784 514.53 285.59C511.516 328.587 518.945 359.27 524.37 381.676C530.747 408.011 534.961 425.409 518.607 442.205C505.628 454.871 493.569 461.418 476.968 464.814C460.773 468.129 440.129 468.326 411.555 468.601C393.814 468.771 371.736 468.983 345.402 470.012L345.357 470.011C342.536 469.926 339.739 469.847 336.965 469.766C309.516 468.969 283.589 468.216 263.048 465.242C240.201 461.933 225.989 456.173 218.324 447.116C212.784 440.572 210.703 432.399 211.961 422.137C211.368 408.197 209.392 395.501 207.478 383.222C204.142 361.811 200.991 341.588 207.004 321.207C213.633 298.741 230.781 277.933 260.969 255.731C298.087 228.437 340.71 208.287 380.99 198.992C421.59 189.624 457.109 191.954 481.002 205.554C482.865 206.616 484.656 207.742 486.372 208.932L486.377 208.929ZM345.418 468.669C371.758 467.643 393.842 467.431 411.587 467.261C469.991 466.698 491.846 466.489 517.689 441.269C533.496 425.033 529.352 407.923 523.082 382.029C517.638 359.54 510.177 328.741 513.209 285.507C515.358 266.395 513.408 249.87 507.411 236.359C501.806 223.732 492.696 213.76 480.329 206.721C456.718 193.282 421.532 190.997 381.253 200.292C341.15 209.544 298.704 229.612 261.735 256.798C196.599 304.702 201.981 339.24 208.794 382.968C210.716 395.299 212.702 408.05 213.294 422.088L213.29 422.198C212.067 432.099 214.049 439.957 219.352 446.222C226.803 455.028 240.766 460.649 263.283 463.909C283.749 466.872 309.638 467.625 337.051 468.422C339.818 468.501 342.606 468.583 345.421 468.668L345.418 468.669Z"
          fill="url(#paint9_linear_1840_2312)"
        />
        <path
          d="M474.412 215.111C495.938 230.041 505.838 254.797 503.12 287.266C501.768 326.278 508.716 354.776 514.297 377.672C521.111 405.62 525.612 424.084 509.506 440.931C497.249 453.036 485.783 459.23 469.915 462.323C454.476 465.332 434.834 465.271 407.648 465.187C390.455 465.133 370.968 465.073 346.944 465.687L340.838 465.453C314.988 464.46 290.57 463.523 271.108 460.516C249.435 457.169 235.862 451.573 228.39 442.903C223.006 436.655 220.839 428.906 221.767 419.212C220.613 406.367 218.39 394.523 216.24 383.066C212.25 361.816 208.48 341.742 213.417 321.629C218.869 299.415 234.399 279.085 262.291 257.654C295.604 232.06 334.713 213.219 372.414 204.6C410.081 195.989 443.744 198.16 467.2 210.715C469.732 212.07 472.136 213.539 474.409 215.115L474.412 215.111ZM235.528 447.45C243.347 452.874 254.995 456.667 271.348 459.193C290.737 462.186 315.118 463.124 340.929 464.115L346.993 464.348C370.995 463.735 390.491 463.795 407.691 463.848C434.802 463.933 454.39 463.994 469.702 461.008C485.283 457.971 496.545 451.885 508.578 440.004C524.147 423.721 519.715 405.545 513.006 378.029C507.404 355.053 500.433 326.456 501.793 287.226C504.471 255.227 494.749 230.867 473.611 216.206C471.383 214.661 469.032 213.226 466.552 211.897C443.371 199.489 410.028 197.359 372.67 205.901C335.155 214.478 296.234 233.233 263.076 258.707C235.448 279.936 220.077 300.019 214.704 321.909C209.837 341.739 213.41 360.756 217.544 382.778C219.706 394.284 221.939 406.18 223.095 419.114L223.093 419.239C222.185 428.581 224.248 436.03 229.401 442.008C231.101 443.98 233.134 445.791 235.526 447.449L235.528 447.45Z"
          fill="url(#paint10_linear_1840_2312)"
        />
        <path
          d="M462.515 221.331C483.18 235.664 493.233 258.809 491.707 288.943C491.9 323.852 498.644 351.144 504.062 373.072C507.642 387.564 510.734 400.08 510.919 410.905C511.119 422.664 507.777 431.804 500.405 439.666C477.046 463.049 455.568 462.669 403.678 461.747C387.977 461.469 370.181 461.154 348.483 461.371L344.704 461.186C280.071 458.001 229.01 455.483 231.575 416.298C229.938 404.656 227.521 393.772 225.182 383.247C220.48 362.093 216.042 342.11 219.864 322.207C224.095 300.182 237.995 280.284 263.607 259.58C293.12 235.728 328.649 218.202 363.644 210.237C398.276 202.351 430.006 204.3 452.99 215.722C456.378 217.406 459.558 219.28 462.515 221.331ZM245.43 443.005C250.106 446.248 256.112 448.898 263.505 451.019C283.81 456.842 312.079 458.234 344.81 459.849L348.549 460.034C370.225 459.818 388.03 460.132 403.74 460.411C429.48 460.867 448.078 461.197 462.642 458.523C477.492 455.796 488.16 450.066 499.468 438.748C514.854 422.336 510.047 402.883 502.771 373.433C497.336 351.436 490.573 324.058 490.379 288.953C491.882 259.273 482.004 236.502 461.709 222.426C458.807 220.413 455.697 218.581 452.372 216.928C429.654 205.638 398.233 203.726 363.896 211.545C329.1 219.466 293.771 236.895 264.417 260.619C239.075 281.103 225.331 300.743 221.167 322.426C217.397 342.053 221.807 361.903 226.478 382.918C228.826 393.488 231.256 404.418 232.899 416.14L232.903 416.279C232.129 427.83 236.164 436.581 245.429 443.007L245.43 443.005Z"
          fill="url(#paint11_linear_1840_2312)"
        />
        <path
          d="M450.519 227.492C458.065 232.726 464.265 239.15 469.039 246.695C476.715 258.825 480.498 273.6 480.289 290.61C481.866 321.237 488.36 346.946 493.579 367.603C501.432 398.703 507.11 421.172 491.301 438.393C469.37 460.684 448.954 459.975 399.629 458.259C385.444 457.766 369.366 457.206 350.018 457.049L348.554 456.959C288.048 453.205 240.251 450.244 241.381 413.378C239.367 403.082 236.834 393.323 234.385 383.883C228.896 362.738 223.712 342.765 226.369 322.995C229.316 301.071 241.564 281.533 264.916 261.506C290.638 239.449 322.491 223.258 354.611 215.913C386.077 208.719 415.762 210.362 438.199 220.541C442.634 222.554 446.744 224.874 450.519 227.492ZM255.307 438.594C273.101 450.936 307.982 453.099 348.679 455.625L350.108 455.712C369.438 455.869 385.525 456.429 399.718 456.923C448.625 458.623 468.868 459.325 490.361 437.482C505.647 420.83 500.047 398.658 492.296 367.97C487.064 347.256 480.553 321.475 478.968 290.675C479.173 273.883 475.456 259.35 467.919 247.44C460.895 236.336 450.703 227.698 437.628 221.768C415.448 211.704 386.057 210.091 354.872 217.221C322.964 224.517 291.319 240.604 265.764 262.518C242.689 282.31 230.59 301.573 227.692 323.139C225.068 342.645 230.221 362.496 235.676 383.513C238.136 392.994 240.68 402.799 242.705 413.16L242.716 413.31C242.347 424.725 246.936 432.789 255.311 438.597L255.307 438.594Z"
          fill="url(#paint12_linear_1840_2312)"
        />
        <path
          d="M438.57 233.689C457.432 246.771 468.043 267.03 468.872 292.248C471.605 318.354 477.502 340.93 482.708 360.847C491.602 394.889 498.03 419.485 482.203 437.126C461.688 458.347 442.305 457.283 395.479 454.716C382.845 454.022 368.525 453.239 351.555 452.733C324.606 450.655 299.18 448.692 280.919 442.949C260.797 436.62 251.349 426.3 251.193 410.469C248.965 401.74 246.439 393.334 243.994 385.207C237.6 363.941 231.562 343.854 232.973 324.102C234.544 302.146 245.109 282.869 266.223 263.434C288.152 243.254 316.216 228.415 345.238 221.652C373.355 215.101 400.823 216.335 422.584 225.131C428.43 227.493 433.77 230.36 438.572 233.691L438.57 233.689ZM265.197 434.187C269.458 437.142 274.824 439.616 281.349 441.669C299.469 447.368 324.823 449.324 351.666 451.395C368.622 451.9 382.949 452.686 395.59 453.379C441.982 455.922 461.186 456.976 481.246 436.226C496.559 419.159 490.21 394.858 481.423 361.225C476.205 341.255 470.291 318.621 467.549 292.404L467.545 292.357C467.017 276.215 462.535 262.215 454.217 250.749C446.471 240.072 435.653 231.871 422.056 226.375C400.541 217.68 373.351 216.465 345.497 222.955C316.7 229.667 288.858 244.388 267.101 264.409C246.264 283.588 235.841 302.572 234.298 324.155C232.902 343.659 238.91 363.633 245.266 384.781C247.721 392.948 250.261 401.391 252.501 410.176L252.521 410.336C252.602 420.484 256.694 428.289 265.199 434.188L265.197 434.187Z"
          fill="url(#paint13_linear_1840_2312)"
        />
        <path
          d="M426.828 239.984C432.192 243.704 436.936 248.005 441.035 252.869C450.546 264.156 456.067 277.963 457.444 293.9C460.956 314.979 466.366 334.645 471.135 351.997C481.202 388.601 489.151 417.511 473.094 435.862C453.975 456.043 435.595 454.591 391.183 451.088C380.146 450.218 367.636 449.23 353.081 448.416C325.737 445.801 305.167 443.532 289.221 438.105C270.808 431.839 261.837 422.132 261 407.562C258.816 400.737 256.499 394.057 254.259 387.598C239.213 344.227 226.217 306.769 267.514 265.362C285.92 246.91 309.396 233.812 335.4 227.484C359.93 221.513 384.919 222.21 405.764 229.443C413.577 232.155 420.614 235.68 426.825 239.988L426.828 239.984ZM275.128 429.821C279.04 432.535 283.877 434.857 289.686 436.832C305.498 442.213 325.995 444.473 353.226 447.079C367.769 447.892 380.288 448.88 391.333 449.752C435.29 453.221 453.486 454.655 472.135 434.972C487.685 417.202 479.818 388.601 469.86 352.391C465.079 335.003 459.658 315.292 456.133 294.135L456.126 294.083C454.777 278.409 449.355 264.84 440.013 253.752C431.322 243.438 419.643 235.683 405.303 230.706C384.693 223.554 359.964 222.87 335.679 228.78C309.919 235.05 286.668 248.023 268.441 266.293C249.803 284.979 241.104 303.843 241.064 325.655C241.028 345.344 248.067 365.634 255.518 387.117C257.769 393.609 260.099 400.322 262.296 407.191L262.327 407.358C262.85 416.827 266.987 424.172 275.129 429.819L275.128 429.821Z"
          fill="url(#paint14_linear_1840_2312)"
        />
        <path
          d="M415.206 246.412C419.128 249.132 422.74 252.14 425.991 255.409C436.91 266.388 443.648 279.89 446.013 295.544C449.646 310.678 453.975 325.232 458.163 339.307C464.19 359.562 469.881 378.695 471.911 394.872C474.18 412.962 471.663 425.585 463.99 434.596C455.363 443.923 446.441 448.479 434.136 449.851C422.241 451.179 407.334 449.565 386.701 447.33C377.323 446.314 366.696 445.163 354.606 444.097C332.148 441.426 312.365 438.769 297.527 433.256C280.829 427.05 272.337 417.959 270.816 404.654C269.12 400.317 267.39 395.999 265.714 391.822C247.713 346.927 232.166 308.154 268.8 267.292C283.39 251.023 302.823 239.325 324.994 233.461C345.636 228.002 367.741 228.004 387.242 233.471C397.596 236.373 407.064 240.772 415.199 246.413L415.206 246.412ZM285.122 425.502C288.668 427.962 292.955 430.113 298.026 431.996C312.729 437.46 332.431 440.106 354.786 442.762C366.87 443.827 377.504 444.977 386.887 445.995C428.523 450.506 445.757 452.375 463.014 433.717C479.199 414.709 468.86 379.959 456.889 339.725C452.695 325.624 448.356 311.044 444.714 295.866L444.703 295.811C442.383 280.428 435.768 267.157 425.037 256.371C415.192 246.475 401.989 239.003 386.855 234.76C367.575 229.355 345.714 229.355 325.299 234.752C303.384 240.548 284.186 252.102 269.776 268.171C233.715 308.394 249.117 346.807 266.95 391.286C268.636 395.492 270.38 399.839 272.087 404.208L272.12 404.288L272.131 404.374C273.116 413.185 277.316 420.089 285.12 425.501L285.122 425.502Z"
          fill="url(#paint15_linear_1840_2312)"
        />
        <path
          d="M403.661 252.832C405.791 254.31 407.842 255.871 409.803 257.513C422.459 268.104 431.024 281.818 434.572 297.176C436.831 304.063 439.213 311.076 441.516 317.862C457.807 365.852 473.194 411.18 454.89 433.336C438.48 451.585 420.764 449.01 381.949 443.372C374.328 442.266 365.692 441.012 356.135 439.781C316.218 433.834 285.317 427.258 280.644 401.753L279.641 399.613C268.594 376.034 258.158 353.761 254.497 332.258C250.387 308.114 255.341 288.084 270.084 269.228C280.937 255.35 296.121 245.133 313.994 239.68C330.654 234.597 348.809 233.793 366.496 237.351C380.29 240.127 392.992 245.433 403.661 252.832ZM295.25 421.252C308.622 430.526 330.586 434.616 356.355 438.454C365.907 439.683 374.551 440.938 382.177 442.048C401.566 444.864 415.575 446.9 426.706 445.996C438.074 445.073 446.207 441.03 453.899 432.477C471.692 410.939 456.424 365.956 440.258 318.332C437.95 311.535 435.565 304.506 433.301 297.606L433.286 297.549C429.804 282.458 421.386 268.972 408.939 258.557C397.319 248.832 382.541 241.956 366.201 238.667C348.726 235.149 330.795 235.943 314.344 240.963C296.744 246.333 281.796 256.388 271.12 270.038C256.635 288.567 251.769 308.254 255.81 331.996C259.44 353.327 269.838 375.519 280.85 399.016L281.923 401.309L281.939 401.393C283.488 409.962 288.163 416.337 295.254 421.255L295.25 421.252Z"
          fill="url(#paint16_linear_1840_2312)"
        />
        <path
          d="M392.469 259.482C407.425 269.855 418.02 283.443 423.12 298.796C436.438 329.347 445.156 354.089 449.771 374.435C455.944 401.651 454.604 421.042 445.786 432.073C430.654 449.488 413.76 446.223 376.746 439.068C371.035 437.964 364.563 436.714 357.652 435.461C324.594 429.199 296.722 422.126 290.474 398.851C278.54 377.296 266.827 354.352 261.925 332.551C256.652 309.114 259.736 289.033 271.35 271.162C278.79 259.719 289.344 251.332 302.719 246.229C314.892 241.587 329.024 239.947 343.587 241.491C360.927 243.33 378.186 249.65 392.187 259.287C392.281 259.352 392.374 259.416 392.467 259.481L392.469 259.482ZM305.6 417.257C318 425.857 336.898 430.16 357.93 434.142C364.844 435.397 371.32 436.65 377.034 437.753C413.527 444.806 430.183 448.026 444.777 431.231C459.839 412.385 452.358 369.233 421.887 299.339L421.867 299.284C416.859 284.187 406.429 270.815 391.692 260.595C391.599 260.53 391.508 260.467 391.417 260.404C377.59 250.887 360.54 244.646 343.41 242.827C329.053 241.305 315.135 242.916 303.159 247.483C290.064 252.479 279.734 260.687 272.46 271.874C261.06 289.417 258.037 309.154 263.227 332.217C268.097 353.879 279.782 376.75 291.683 398.241L291.742 398.389C293.884 406.437 298.751 412.504 305.604 417.257L305.6 417.257Z"
          fill="url(#paint17_linear_1840_2312)"
        />
        <path
          d="M381.704 266.391C395.248 275.784 405.717 287.621 411.65 300.404C424.568 323.029 435.01 349.462 440.295 372.926C447.961 406.947 442.56 423.188 436.68 430.823C422.726 447.575 405.362 443.081 370.769 434.127C367.068 433.168 363.241 432.179 359.16 431.146C333.734 424.878 308.245 416.999 300.315 395.96C284.449 371.481 274.263 350.507 269.176 331.836C263.042 309.324 264.164 290.12 272.606 273.12C280.988 256.254 298.006 246.827 320.526 246.576C337.812 246.383 356.615 251.58 373.473 261.208C376.325 262.838 379.072 264.569 381.702 266.393L381.704 266.391ZM316.301 413.433C327.787 421.399 343.66 425.932 359.516 429.839C363.604 430.874 367.432 431.863 371.135 432.824C388.637 437.354 401.283 440.629 411.466 440.792C421.976 440.96 429.213 437.731 435.655 429.998C443.694 419.56 444.885 399.403 438.996 373.259C433.735 349.91 423.341 323.598 410.477 301.075L410.453 301.026C403.484 285.993 390.108 272.267 372.793 262.376C356.131 252.859 337.558 247.723 320.502 247.914C298.519 248.157 281.93 257.313 273.793 273.687C265.507 290.371 264.417 309.261 270.46 331.441C275.516 349.993 285.66 370.868 301.475 395.258L301.537 395.384C304.372 402.948 309.586 408.777 316.301 413.433Z"
          fill="url(#paint18_linear_1840_2312)"
        />
        <path
          d="M371.918 273.898C383.761 282.111 393.638 291.872 400.157 302.002C413.693 320.176 425.156 346.121 430.816 371.415C436.687 397.641 435.504 418.84 427.572 429.574C421.387 437.409 413.799 440.396 402.954 439.286C392.742 438.237 379.705 433.598 363.202 427.724L360.656 426.82C339.962 419.918 319.514 411.048 310.173 393.067C294.44 372.182 282.774 350.76 276.429 331.11C269.625 310.036 268.758 291.188 273.853 275.092C277.775 262.713 287.257 254.99 301.274 252.755C316.268 250.364 335.861 254.441 355.028 263.939C360.965 266.882 366.637 270.236 371.918 273.898ZM327.721 410.266C337.332 416.931 349.181 421.566 361.119 425.548L363.677 426.458C397.293 438.423 414.166 444.427 426.523 428.777C434.202 418.383 435.298 397.591 429.512 371.749C423.886 346.618 412.505 320.847 399.065 302.812L399.039 302.775C389.737 288.308 373.469 274.597 354.406 265.148C335.474 255.766 316.168 251.732 301.437 254.081C287.966 256.229 278.866 263.622 275.114 275.466C270.106 291.288 270.973 309.859 277.688 330.663C283.991 350.189 295.602 371.496 311.267 392.28L311.326 392.371C315.126 399.707 320.85 405.507 327.716 410.269L327.721 410.266Z"
          fill="url(#paint19_linear_1840_2312)"
        />
        <path
          d="M364.577 282.887C373.887 289.344 382.219 296.466 388.65 303.602C403.406 318.686 415.327 342.86 421.34 369.905C427.006 395.397 425.907 417.786 418.468 428.334C406.983 443.938 388.71 436.146 362.146 422.481C342.394 412.958 329.017 402.691 320.059 390.177C304.087 371.843 291.511 351.165 283.695 330.382C276.486 311.215 273.518 292.787 275.116 277.094C276.046 267.96 280.51 261.916 288.381 259.13C299.606 255.155 317.833 258.333 338.387 267.848C347.459 272.048 356.401 277.21 364.584 282.885L364.577 282.887ZM342.068 409.425C348.021 413.554 354.875 417.47 362.761 421.273C392.493 436.567 407.432 441.101 417.395 427.564C424.609 417.335 425.623 395.363 420.036 370.234C414.162 343.815 402.063 319.256 387.666 304.539C375.671 291.234 357.033 277.981 337.792 269.071C317.546 259.696 299.683 256.536 288.781 260.396C281.46 262.99 277.307 268.641 276.435 277.194C274.861 292.681 277.799 310.899 284.938 329.875C292.706 350.525 305.206 371.073 321.088 389.299L321.125 389.347C326.395 396.717 333.24 403.306 342.064 409.426L342.068 409.425Z"
          fill="url(#paint20_linear_1840_2312)"
        />
        <path
          d="M366.705 297.61C370.359 300.144 373.844 302.682 377.103 305.177C393.013 317.363 405.682 340.398 411.86 368.376C417.316 393.088 416.312 416.675 409.36 427.073C406.517 431.323 402.773 433.752 398.229 434.29C381.706 436.244 355.665 412.847 329.985 387.269C294.263 351.694 281.144 308.825 276.425 279.127C275.304 272.071 276.828 267.195 280.955 264.632C294.853 256.003 336.392 276.583 366.705 297.607L366.705 297.61ZM372.365 422.942C382.26 429.804 391.122 433.78 398.114 432.954C402.298 432.458 405.618 430.297 408.26 426.345C415.015 416.242 415.938 393.077 410.557 368.707C404.445 341.022 391.949 318.259 376.276 306.256C347.024 283.855 296.49 256.545 281.628 265.774C278.022 268.014 276.715 272.421 277.741 278.879C282.428 308.384 295.461 350.97 330.935 386.301C345.245 400.553 359.654 414.129 372.364 422.944L372.365 422.942Z"
          fill="url(#paint21_linear_1840_2312)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1840_2312"
          x1="367.927"
          y1="274.463"
          x2="649.187"
          y2="438.935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1840_2312"
          x1="366.626"
          y1="277.157"
          x2="639.589"
          y2="436.494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1840_2312"
          x1="365.308"
          y1="279.836"
          x2="630.006"
          y2="434.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1840_2312"
          x1="363.957"
          y1="282.488"
          x2="620.412"
          y2="431.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1840_2312"
          x1="362.576"
          y1="285.123"
          x2="610.839"
          y2="429.111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1840_2312"
          x1="361.185"
          y1="287.745"
          x2="601.297"
          y2="426.639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1840_2312"
          x1="359.728"
          y1="290.328"
          x2="591.779"
          y2="424.161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1840_2312"
          x1="357.894"
          y1="291.04"
          x2="581.938"
          y2="419.823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1840_2312"
          x1="356.707"
          y1="295.409"
          x2="572.759"
          y2="419.135"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1840_2312"
          x1="355.122"
          y1="297.897"
          x2="563.306"
          y2="416.604"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1840_2312"
          x1="353.476"
          y1="300.335"
          x2="553.879"
          y2="414.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1840_2312"
          x1="351.754"
          y1="302.715"
          x2="544.479"
          y2="411.434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1840_2312"
          x1="349.928"
          y1="305.052"
          x2="535.108"
          y2="408.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1840_2312"
          x1="348.015"
          y1="307.297"
          x2="525.81"
          y2="406.114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1840_2312"
          x1="345.969"
          y1="309.447"
          x2="516.548"
          y2="403.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1840_2312"
          x1="343.726"
          y1="311.488"
          x2="507.362"
          y2="400.483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1840_2312"
          x1="341.281"
          y1="313.346"
          x2="498.287"
          y2="397.458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1840_2312"
          x1="338.51"
          y1="315.01"
          x2="489.312"
          y2="394.252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1840_2312"
          x1="335.452"
          y1="316.423"
          x2="480.51"
          y2="390.717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1840_2312"
          x1="332.081"
          y1="317.638"
          x2="471.847"
          y2="386.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1840_2312"
          x1="328.409"
          y1="318.643"
          x2="463.343"
          y2="382.334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1840_2312"
          x1="324.053"
          y1="320.079"
          x2="454.358"
          y2="378.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="#18203C" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
