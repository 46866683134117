import {
  Box,
  Container,
  Grid,
  Typography,
  Link as Anchor,
} from "@mui/material";
import { Link } from "react-scroll";

import {
  DiscordIcon,
  DownArrowIcon,
  HeaderBottomLinesIcon,
  HeaderDash,
  HeaderLinesIcon,
  HeaderUpperLinesIcon,
  PartyIcon,
} from "../assets/icons";
import { SecondaryButton, LaunchButton } from "../shared";

export const Header = () => {
  return (
    <Grid
      container
      pt={{ xs: 9.125, md: 20 }}
      pb={{ xs: 0, md: 30 }}
      position={"relative"}
      id="home"
    >
      <Container maxWidth="xl">
        <Grid item xs={12} sm={9} md={6} lg={4}>
          <Typography
            variant="h1"
            sx={(theme) => ({
              fontWeight: 200,
              color: "neutralWhite",
              fontFamily: "AcehLight",
              lineHeight: { xs: "43px", sm: "60.84px" },
              [theme.breakpoints.down("sm")]: {
                fontSize: "48px",
              },
            })}
          >
            Building
            <Typography
              variant="inherit"
              sx={(theme) => ({
                fontFamily: "AcehMedium",
                lineHeight: { xs: "43px", sm: "60.84px" },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "48px",
                },
              })}
            >
              Decentralized Solutions
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              color: "neutralGrey",
              mt: { xs: 2.5, sm: 3 },
              pr: { xs: 7.5, sm: 0 },
            }}
          >
            {"Our mission is to create a "}
            <Typography
              variant="caption"
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                display: "initial",
                color: "neutralWhite",
                lineHeight: 1,
                letterSpacing: 1,
              }}
            >
              {"self-sustaining, self-governing network "}
            </Typography>
            {"that builds Omnichain solutions, products, and services."}
          </Typography>
        </Grid>
        <Grid
          container
          flexDirection={{
            xs: "column-reverse",
            md: "column",
            alignItems: "flex-start",
          }}
        >
          <Link to={"services"} activeClass="active" spy>
            <DownArrowIcon sx={{ mt: { xs: 25, md: 6 }, cursor: "pointer" }} />
          </Link>

          <Grid
            container
            item
            columnSpacing={2.5}
            sx={{
              mt: { xs: 5.4, md: 15 },
              "@media (max-width:768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Grid item display={"flex"} alignItems="center">
              <Box
                sx={{
                  width: "42px",
                  height: "42px",
                  bgcolor: "neutralWhite",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DiscordIcon sx={{ fill: "black", width: "17px" }} />
              </Box>
              <Anchor
                href="https://discord.gg/AbEkSs4Fte"
                target="_blank"
                rel="noreferrer"
              >
                <SecondaryButton>Join our Discord</SecondaryButton>
              </Anchor>
            </Grid>
            <Grid
              item
              display={"flex"}
              alignItems="center"
              sx={{
                "@media (max-width:768px)": {
                  mt: 2,
                },
              }}
            >
              <Box
                sx={{
                  width: "159px",
                  height: "42px",
                  bgcolor: "themeDarkGrey",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "lowFidelityText",
                }}
              >
                <PartyIcon />
                <Typography ml={1}>The NFT Suite</Typography>
              </Box>
              <LaunchButton
                sx={{
                  textColor: "#fff",
                  border: "1.44828px solid",
                  borderColor: "lowFidelitySecondary",
                }}
                disabled
              >
                NFT Suite Coming Soon
                {/* Launch App */}
              </LaunchButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <HeaderDash
        sx={{
          position: "absolute",
          top: "144px",
          right: 0,
          display: { xs: "none", lg: "block" },
          "@media (max-width:1399px)": {
            width: "50%",
          },
        }}
      />
      <HeaderLinesIcon
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          right: 0,
          top: "187px",
          zIndex: "-1",
        }}
      />
      <HeaderUpperLinesIcon
        sx={{
          display: { xs: "none", sm: "block" },
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <HeaderBottomLinesIcon
        sx={{
          position: "absolute",
          right: 0,
          zIndex: -1,
          display: { xs: "none", sm: "block" },
        }}
      />
    </Grid>
  );
};
